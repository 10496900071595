import React, { useEffect, useState } from "react";
import DashboardLayout from "../../components/SharedLayouts/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import useCurrency from "../../hooks/useCurrency";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { getInvestment } from "../../store/actions/investment/getInvestment";
import ConfirmDeleteModal from "../../components/modal/ConfirmDeleteModal";
import BackArrow2 from "../../components/BackArrow2";
import AboutInvesment from "../../components/investment/AboutInvesment";
import { getBusinessProducts } from "../../store/actions/businesses/getBusinessProducts";
import { LiaAngleLeftSolid } from "react-icons/lia";
import CampaignCard from "../../components/investment/CampaignCard";
import FilterButton from "../../components/Buttons/FilterButton";
import UpdatTimelineModal from "../../components/modal/UpdatTimelineModal";
import Loader from "../../components/SharedLayouts/Loader";
import StartInvestmentModal from "../../components/modal/StartInvestmentModal";
import { closeInvestment } from "../../store/actions/investment/closeInvestment";
import PayoutModal from "../../components/modal/PayoutModal";
import { getAllProductInvestment } from "../../store/actions/investment/getInvestments";

const BusinessDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [showTimelines, setShowTimelines] = useState();
  const { state } = useLocation();

  const business = state?.business;

  useEffect(() => {
    if (!business) {
      navigate(-1);
    }
  }, []);
  const [showDeleteModal, setShowDeleteModal] = useState();
  const [activeTab, setActiveTab] = useState("active");

  const { businessProducts, pending } = useSelector(
    (state) => state?.investment
  );

  const [openStartModal, setOpenStartModal] = useState(false);

  const handleCloseStartModal = () => {
    setOpenStartModal(false);
  };

  const [_currency, getAmount] = useCurrency();
  const currency = useSelector((state) => state.dashboard.currency) || "NGR";
  const { id } = useParams();
  const user = useSelector((state) => state.onboarding.user);

  useEffect(() => {
    dispatch(getBusinessProducts(id));
  }, [currency]);

  const refresh = () => {
    dispatch(getBusinessProducts(id));
  };

  const handleDelete = () => {
    // dispatch(deleteInvestment({ id, navigate }));
    setShowDeleteModal(false);
  };

  useEffect(() => {
    if (!business) {
      navigate(-1);
    }
  }, []);

  return (
    <DashboardLayout>
      <div className="px-8">
        <div className="flex items-center mb-8">
          <img src="/Assets/bread.png" alt="" className="w-8 h-8 mr-2" />
          <img src="/Assets/chev.png" alt="" className="w-8 h-8 mr-2" />
          <Link
            to={"/businesses"}
            className="font=[500] text-[#475467] text-base hidden sm:flex"
          >
            Business
          </Link>
          <Link to={"/businesses"}>
            <img
              src="/Assets/userDot.png"
              alt=""
              className="w-8 h-8 flex sm:hidden"
            />
          </Link>
          <img src="/Assets/chev.png" alt="" className="w-8 h-8 mr-2" />
          <span className="font-[500] text-[#475467] text-base bg-[#F9FAFB] p-1 rounded-md ">
            Business Detail
          </span>
        </div>
        {/* <BackArrow2 mb={10} ml={0} /> */}

        <div className="">
          <div className="flex flex-col md:flex-row flex-wrap justify-between items-center mb-4 w-full md:gap-10">
            {/* <h1 className="mb-4 md:mb-0 font-[600] text-2xl md:text-3xl min-w-[max-content] capitalize">
              {business?.name}
            </h1> */}

            {/* <div className="w-full overflow-x-auto mb-4 md:mb-0">

              <div className="flex items-center w-[max-content] xl:w-full justify-end gap-[24px] ">
                {user?.roles &&
                  !user?.roles?.some(
                    (role) => role.name === "Admin_Tier_1"
                  ) && (
                    <div
                      onClick={() => setShowDeleteModal(true)}
                      className="cursor-pointer text-sm font-[600] text-[#D92D20] md:mb-0 md:mr-2 !w-[max-content]"
                    >
                      Delete Business
                    </div>
                  )}
              </div>
            </div> */}
          </div>

          {pending || !business?.name ? (
            <Loader />
          ) : (
            <div className="w-[100%] min-h-[90vh] h-full flex-wrap rounded-[12px] gap-10 p-6 bg-[#FFF] border-[#F2F2F2] border-[1.1px] drop-shadow-custom  max-w-[1664px] flex">
              <div className="flex flex-col flex-[5]">
                <>
                  <div
                    onClick={() => navigate(-1)}
                    className="flex cursor-pointer items-center mb-4"
                  >
                    <LiaAngleLeftSolid
                      size={18}
                      color="#131E3D"
                      className="mx-[2px]  "
                    />
                    <h3 className="text-[14px] font-[400] text-[#131E3D]">
                      Back
                    </h3>
                  </div>
                  <h2 className="text-[54px] capitalize  mb-6 font-[400] leading-[59.4px] tracking-[-3.1%] max-w-[650px]">
                    {business?.name}
                  </h2>
                  <AboutInvesment
                    businessProduct={businessProducts[0]}
                    business={business}
                    refresh={refresh}
                  />
                </>
              </div>
              <div className="flex pt-[40px] flex-col flex-[4]">
                <div className="w-min border-[#F0F0F5] drop-shadow-custom mb-4 p-2 h-[49px] border-[1px] rounded-[41px] flex justify-center items-center">
                  <FilterButton
                    items={[
                      { label: "Active", value: "active" },
                      { label: "Closed", value: "closed" },
                      { label: "Exited", value: "exited" },
                    ]}
                    active={activeTab}
                    setActive={setActiveTab}
                  />
                </div>
                <>
                  {activeTab === "active"
                    ? businessProducts[0]?.cycles
                        ?.filter(
                          (investment) =>
                            investment?.status == "1" ||
                            investment?.status == "2" ||
                            investment?.status === null
                        )
                        ?.map((item, ind) => {
                          return (
                            <CampaignCard
                              key={ind}
                              refresh={refresh}
                              showTimelines={() => {
                                setShowTimelines({
                                  cycle: item,
                                  investment: businessProducts[0],
                                });
                              }}
                              item={item}
                              investment={businessProducts[0]}
                              id={item?.id}
                              status={item?.status}
                              title={item?.name}
                              tenure={item?.duration}
                              nReturn={Number(item?.roi)}
                              onClickPayout={() => {
                                setOpenModal({
                                  ...item,
                                  investment_id: businessProducts[0]?.id,
                                });
                              }}
                              onClick={() => {
                                setOpenStartModal({
                                  ...item,
                                  investment_id: businessProducts[0]?.id,
                                });
                              }}
                              onClickClose={() => {
                                setOpenStartModal({
                                  ...item,
                                  investment_id: businessProducts[0]?.id,
                                });
                              }}
                              paymentSchedules={item?.paymentSchedules}
                              payoutType={
                                item?.repayment_type === "PMCE" ||
                                item?.payout_type === "PMCE"
                                  ? "Profit Monthly, Capital at the end"
                                  : item?.repayment_type === "PACM" ||
                                    item?.payout_type === "PACM"
                                  ? "Profit and Capital Monthly"
                                  : item?.repayment_type === "PACE" ||
                                    item?.payout_type === "PACE"
                                  ? "Profit and Capital at the end"
                                  : item?.repayment_type || item?.payout_type
                              }
                              dueDate={item?.due_date}
                              invested={item?.total_amount_invested || 0}
                              totalAmount={Number(item?.amount)}
                              currency={item?.currency === "USD" ? "$" : "₦"}
                            />
                          );
                        })
                    : businessProducts[0]?.cycles
                        ?.filter(
                          (investment) =>
                            investment?.status == "0" ||
                            investment?.status == "6"
                        )
                        ?.map((item, ind) => {
                          return (
                            <CampaignCard
                              key={ind}
                              refresh={refresh}
                              showTimelines={() => {
                                setShowTimelines({
                                  cycle: item,
                                  investment: businessProducts[0],
                                });
                              }}
                              item={item}
                              investment={businessProducts[0]}
                              id={item?.id}
                              status={item?.status}
                              title={item?.name}
                              tenure={item?.duration}
                              nReturn={Number(item?.roi)}
                              onClickPayout={() => {
                                setOpenModal({
                                  ...item,
                                  investment_id: businessProducts[0]?.id,
                                });
                              }}
                              onClick={() => {
                                setOpenStartModal({
                                  ...item,
                                  investment_id: businessProducts[0]?.id,
                                });
                              }}
                              onClickClose={() => {
                                setOpenStartModal({
                                  ...item,
                                  investment_id: businessProducts[0]?.id,
                                });
                              }}
                              paymentSchedules={item?.paymentSchedules}
                              payoutType={
                                item?.repayment_type === "PMCE" ||
                                item?.payout_type === "PMCE"
                                  ? "Profit Monthly, Capital at the end"
                                  : item?.repayment_type === "PACM" ||
                                    item?.payout_type === "PACM"
                                  ? "Profit and Capital Monthly"
                                  : item?.repayment_type === "PACE" ||
                                    item?.payout_type === "PACE"
                                  ? "Profit and Capital at the end"
                                  : item?.repayment_type || item?.payout_type
                              }
                              dueDate={item?.due_date}
                              invested={item?.total_amount_invested || 0}
                              totalAmount={Number(item?.amount)}
                              currency={item?.currency === "USD" ? "$" : "₦"}
                            />
                          );
                        })}
                </>
              </div>
            </div>
          )}

          {/* {investment?.video_link && (

            <div className="my-5">
              <p className="text-lg mb-3 font-[500]">
                {investment?.video_title}
              </p>
              <YouTube
                videoId={getYoutubeVideoId(investment?.video_link)}
                opts={{ width: "100%", height: "300px" }}
              />
            </div>

          )} */}
        </div>
      </div>

      <PayoutModal
        open={openModal}
        cycle={openModal}
        refresh={refresh}
        invID={openModal?.investment_id}
        cycle_id={openModal?.id}
        onClose={handleCloseModal}
        setOpenModal={setOpenModal}
      />

      {showTimelines && (
        <UpdatTimelineModal
          investment_id={showTimelines?.investment?.id}
          open={showTimelines?.cycle}
          onClose={() => setShowTimelines(false)}
        />
      )}
      <StartInvestmentModal
        type={2}
        refresh={refresh}
        open={openStartModal}
        setOpenStartModal={setOpenStartModal}
        invID={openStartModal?.investment_id}
        cycle_id={openStartModal?.id}
        name={openStartModal?.name}
        invType={openStartModal?.investment_type}
        payout_type={openStartModal?.payout_type}
        currency={openStartModal?.currency}
        amount={openStartModal?.paymentSchedules?.total_amount}
        onClose={handleCloseStartModal}
      />
      {showDeleteModal && (
        <ConfirmDeleteModal
          open={showDeleteModal}
          handleDelete={handleDelete}
          onClose={() => setShowDeleteModal(false)}
        />
      )}
    </DashboardLayout>
  );
};

export default BusinessDetail;
