import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../Helper/proccessErrorMessage";
import { API_CREATE_PRODUCT_TIMELINE } from "../../../config/api";

export const updateTimeline = createAsyncThunk(
  "updateTimeline/create",
  async ({ data, onClose, setSubmitting }, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "POST",
        path: API_CREATE_PRODUCT_TIMELINE,
        data: data,
      });
      toast.success("Timeline updated successfully");

      setSubmitting(false);
      onClose();
      return response?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      setSubmitting(false);
      return rejectWithValue(error.message);
    }
  }
);
