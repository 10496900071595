import React, { useEffect, useRef, useState } from "react";
import DashboardLayout from "../../components/SharedLayouts/DashboardLayout";
import CustomButton from "../../components/Buttons/Button";
import CustomInput from "../../components/inputs/CustomInput";
import SelectField from "../../components/inputs/SelectField";
import { IconButton } from "@mui/material";
import ".//Investment.css";
import ClearIcon from "@mui/icons-material/Clear";
import {
  campaignFrequencyOptions,
  currencyOptions,
  investmentSectorOptions,
  investmentType,
  paymentFrequencyOptions,
  paymentType,
  publicCategoryOptions,
  riskOptions,
} from "../../config/data";
import HalDatePicker from "../../components/inputs/HalDatePicker";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { createInvestmentSchema } from "../../Helper/validationSchemas";
import { getCategories } from "../../store/actions/investment/getCategories";
import { getInvestment } from "../../store/actions/investment/getInvestment";
import { updateInvestment } from "../../store/actions/investment/updateInvestment";
import dayjs from "dayjs";
import ReactQuill from "react-quill";

import BackArrow2 from "../../components/BackArrow2";
import { getServerDate } from "../../config/dateformatter";

function EditInvestment() {
  const [banner, setBanner] = useState(null);
  const [document, setDocument] = useState(null);
  const [description, setDescription] = useState("");
  const [startupInvesting, setStartupInvesting] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [documentsPreview, setDocumentPreview] = useState([]);
  const [myImagePreview, setMyImagePreview] = useState(null);
  const fileInputRef = useRef(null);
  const memoFileInputRef = useRef(null);
  const { id } = useParams();

  const loading = useSelector((state) => state?.investment?.pending) || false;
  const investment = useSelector((state) => state?.investment?.investment);
  const categories =
    useSelector((state) => state?.investment?.categories) || [];

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getCategories());
    dispatch(getInvestment(id));
  }, [dispatch, id]);

  const handleFileChange = (event) => {
    setBanner(event.target.files[0]);
    handleImageChange(event);
  };
  const handleMemoFileChange = (event) => {
    setDocument(event.target.files[0]);
    handleMemoChange(event);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    handleImageChange(e);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleMemoDrop = (e) => {
    e.preventDefault();
    handleMemoChange(e);
  };

  const handleMemoDragOver = (e) => {
    e.preventDefault();
  };

  const containerStyle = {
    width: "100%",
    marginTop: "10px",
    position: "relative",
  };
  const handleImageChange = async (e) => {
    if (!e?.dataTransfer?.files[0] && !e?.target?.files[0]) {
      return;
    }
    const files = e?.dataTransfer?.files || e?.target?.files;
    const convertedFiles = files[0];

    const fileReader = new FileReader();
    fileReader.readAsDataURL(convertedFiles);
    fileReader.addEventListener("load", (result) => {
      setMyImagePreview(result.target.result);
    });
    setBanner(convertedFiles);
  };

  const handleMemoChange = async (e) => {
    if (!e?.dataTransfer?.files[0] && !e?.target?.files[0]) {
      return;
    }
    const files = e?.dataTransfer?.files || e?.target?.files;
    const convertedFiles = files[0];

    const fileReader = new FileReader();
    fileReader.readAsDataURL(convertedFiles);

    setDocument(convertedFiles);

    if (startupInvesting) {
      setDocuments((prevs) => [...prevs, files[0]]);
    }
  };

  const handleRemoveImage = () => {
    setMyImagePreview(null);
    setBanner(null);
  };

  const handleRemoveMemo = (index) => {
    if (documents) {
      const updatedFiles = [...documents];

      updatedFiles.splice(index, 1);
      setDocuments(updatedFiles);
    } else {
      setDocumentPreview(null);
    }
  };

  const handleRemoveInvestmentMemo = () => {
    setDocumentPreview(null);
  };

  const handleDivClick = () => {
    fileInputRef.current.click();
  };

  const handleMemoDivClick = () => {
    memoFileInputRef.current.click();
  };

  const bannerStyle = {
    width: "100%",
    maxWidth: "590px",
    height: "auto",
    border: "1px solid #EAECF0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    justifyContent: "center",
    borderRadius: "10px",
    textAlign: "center",
    lineHeight: 1.8,
    cursor: "pointer",
  };

  useEffect(() => {
    if (investment) {
      form.setFieldValue("name", investment?.name);
      form.setFieldValue("duration", investment?.duration);
      form.setFieldValue("roi", investment?.roi);
      form.setFieldValue(
        "annual_rental_return",
        investment?.annual_rental_return
      );
      form.setFieldValue("maximum_amount", investment?.maximum_amount);
      form.setFieldValue("minimum_amount", investment?.minimum_amount);
      form.setFieldValue(
        "campaign_start_date",
        dayjs(investment?.campaign_start_date)
      );
      form.setFieldValue("currency", {
        value: investment?.currency,
        label: currencyOptions.filter(
          (item) => item.value === investment?.currency
        )[0]?.label,
      });
      form.setFieldValue("investment_category_id", {
        value: investment?.investment_category?.id,
        label: categories.filter(
          (item) => item.id === investment?.investment_category?.id
        )[0]?.name,
      });

      form.setFieldValue("sector", {
        label: investment?.sector,
        value: investmentSectorOptions.filter(
          (item) => item.label === investment?.sector
        )[0]?.value,
      });

      form.setFieldValue(
        "member_admin_perc",
        Number(investment?.member_admin_perc)
      );
      form.setFieldValue(
        "non_member_admin_perc",
        Number(investment?.non_member_admin_perc)
      );

      form.setFieldValue("payment_frequency", {
        value: investment?.payment_frequency,
        label: paymentFrequencyOptions.filter(
          (item) => item.value === investment?.payment_frequency
        )[0]?.label,
      });
      form.setFieldValue("payout_type", {
        value: investment?.payout_type,
        label: paymentType.filter(
          (item) => item?.value === investment?.payout_type
        )[0]?.label,
      });
      form.setFieldValue("property_type", {
        value: investment?.property_type,
        label: investment?.property_type,
      });

      form.setFieldValue("property_location", investment?.property_location);
      form.setFieldValue("investment_type", {
        value: investment?.investment_type,
        label: investmentType.filter(
          (item) => item.value === investment?.investment_type
        )[0]?.label,
      });
      form.setFieldValue("target", {
        value: investment?.target,
        label: publicCategoryOptions.filter(
          (item) => item.value === investment?.target
        )[0]?.label,
      });

      form.setFieldValue("risk_factor", {
        value: investment?.risk_factor,
        label: riskOptions.filter(
          (item) => item.label === investment?.risk_factor
        )[0]?.label,
      });
      form.setFieldValue("campaign_duration", investment?.campaign_duration);
      if (startupInvesting) {
      }
      form.setFieldValue("video_link", investment?.video_link);
      form.setFieldValue("video_title", investment?.video_title);
      form.setFieldValue("investment_memo", investment?.investment_memo);
      form.setFieldValue("annual_dividend", {
        value: investment?.annual_dividend,
        label: investment?.annual_dividend === "Yes" ? "Yes" : "No",
      });
      form.setFieldValue("liquidity", {
        value: investment?.liquidity,
        label: investment?.liquidity === "Annual" ? "Annual" : "None",
      });

      // form.setFieldValue("payout_type", {
      //   value: investment?.payout_type,
      //   label: investment?.payout_type === "PMCE" ? "Profit Monthly, Capital at the end"
      //     :
      //    investment?.payout_type === "PACE" ? "Profit and Capital at the end"

      //    :

      //    "Profit and Capital Monthly"
      // });

      form.setFieldValue("projected_timeline", {
        value: investment?.projected_timeline,
        label:
          investment?.projected_timeline === "5 - 7 years"
            ? "5 - 7 years"
            : "7 - 10 years",
      });

      form.setFieldValue("campaign_duration_type", {
        value: investment?.campaign_duration_type,
        label: campaignFrequencyOptions.filter(
          (item) => item.value === investment?.campaign_duration_type
        )[0]?.label,
      });

      setMyImagePreview(investment?.banner_link);
      setDocumentPreview(investment?.investment_memo);
      setDocuments(investment?.documents || []);
      setDescription(investment?.description);
    }
  }, [investment, categories]);

  const handleSubmit = () => {
    const formData = new FormData();

    formData.append("name", form.values?.name);
    formData.append(
      "investment_category_id",
      form.values?.investment_category_id.value
    );
    formData.append(
      "campaign_start_date",
      getServerDate(form.values?.campaign_start_date)
    );
    formData.append("campaign_duration", form.values?.campaign_duration);
    formData.append(
      "campaign_duration_type",
      form.values?.campaign_duration_type?.value
    );
    formData.append("minimum_amount", form.values?.minimum_amount);
    formData.append("maximum_amount", form.values?.maximum_amount);
    formData.append("dollar", form.values?.dollar);
    formData.append("risk_factor", form.values?.risk_factor?.value);
    formData.append("currency", form.values?.currency?.value);
    formData.append("document_links", form.values?.document_links);
    formData.append("description", description);
    formData.append("sector", form.values?.sector?.label);
    formData.append("target", form.values?.target?.value);
    formData.append("member_admin_perc", form.values?.member_admin_perc);
    formData.append(
      "non_member_admin_perc",
      form.values?.non_member_admin_perc
    );
    formData.append("payout_type", form.values?.payout_type?.value);

    if (banner) {
      formData.append("banner", banner);
    }

    if (startupInvesting) {
      if (form.values.annualized_target_return) {
        formData.append(
          "annualized_target_return",
          form.values?.annualized_target_return
        );
      }
      if (form.values.annual_rental_return) {
        formData.append(
          "annual_rental_return",
          form.values?.annual_rental_return
        );
      }
      if (form.values.projected_timeline?.value) {
        formData.append(
          "projected_timeline",
          form.values?.projected_timeline?.value
        );
      } else if (form.values?.projected_timeline_days) {
        formData.append(
          "projected_timeline",
          `${form.values?.projected_timeline_days} days`
        );
      } else if (form.values?.projected_timeline_years) {
        formData.append(
          "projected_timeline",
          `${form.values?.projected_timeline_years} years`
        );
      }

      if (form.values.liquidity?.value) {
        formData.append("liquidity", form.values?.liquidity?.value);
      }
      if (form.values?.property_type) {
        formData.append("property_type", form.values?.property_type?.label);
      }
      if (form.values.annual_dividend?.value) {
        formData.append("annual_dividend", form.values?.annual_dividend?.value);
      }

      const updatedFiles = documents.filter((item) => item instanceof File);
      updatedFiles.forEach((document) => {
        formData.append("documents[]", document);
      });
    } else {
      if (form.values?.roi) {
        formData.append("roi", form.values?.roi);
      }
      if (form.values?.payment_frequency?.value) {
        formData.append(
          "payment_frequency",
          form.values?.payment_frequency.value
        );
      }
      if (form.values?.duration) {
        formData.append("duration", form.values?.duration);
      }
      if (form.values?.duration_type?.value) {
        formData.append("duration_type", form.values?.duration_type?.value);
      }

      if (document) {
        formData.append("investment_memo", document);
      }
    }

    if (form?.values?.property_location) {
      formData.append("property_location", form.values?.property_location);
    }

    form.values?.video_link &&
      formData.append("video_link", form.values?.video_link);
    form.values?.video_title &&
      formData.append("video_title", form.values?.video_title);

    dispatch(
      updateInvestment({
        data: formData,
        id,
        navigate,
      })
    );
  };

  const clearIconStyle = {
    position: "absolute",
    top: "0px",
    right: "10px",
    background: "#159AA8",
    borderRadius: "50%",
    padding: "4px",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
    cursor: "pointer",
  };

  const form = useFormik({
    initialValues: {
      name: investment?.name,
      banner: "",
      banner_link: "",
      investment_category_id: {
        value: investment?.investment_category?.id,
        label: categories.filter(
          (item) => item.id === investment?.investment_category?.id
        )[0]?.name,
      },
      property_type: {
        value: investment?.property_type,
        label: investment?.property_type,
      },
      annual_rental_return: investment?.annual_rental_return,
      annualized_target_return: investment?.annualized_target_return,
      liquidity: investment?.liquidity,
      projected_timeline: investment?.projected_timeline,
      projected_timeline_years: investment?.projected_timeline?.includes(
        "years"
      )
        ? investment?.projected_timeline?.slice(0, -6)
        : "",
      projected_timeline_days: investment?.projected_timeline?.includes("days")
        ? investment?.projected_timeline?.slice(0, -5)
        : "",
      annual_dividend: investment?.annual_dividend,
      video_link: investment?.video_link,
      video_title: investment?.video_title,
      sector: {
        label: investment?.sector,
        value: investmentSectorOptions.filter(
          (item) => item.label === investment?.sector
        )[0]?.value,
      },

      campaign_duration_type: {
        value: investment?.campaign_duration_type,
        label: campaignFrequencyOptions.filter(
          (item) => item.value === investment?.campaign_duration_type
        )[0]?.label,
      },
      duration: investment?.duration,
      duration_type: { label: "Months", value: "Months" },
      payment_frequency: {
        value: investment?.payment_frequency,
        label: paymentFrequencyOptions.filter(
          (item) => item.value === investment?.payment_frequency
        )[0]?.label,
      },
      payout_type: {
        value: investment?.payout_type,
        label: paymentType.filter(
          (item) => item?.value === investment?.payout_type
        )[0]?.label,
      },
      roi: "",
      risk_factor: {
        value: investment?.risk_factor,
        label: riskOptions.filter(
          (item) => item.label === investment?.risk_factor
        )[0]?.label,
      },
      minimum_amount: 0,
      maximum_amount: 0,
      campaign_start_date: dayjs(investment?.campaign_start_date),
      member_admin_perc: Number(investment?.member_admin_perc),
      non_member_admin_perc: Number(investment?.non_member_admin_perc),
      target: {
        value: investment?.target,
        label: publicCategoryOptions.filter(
          (item) => item.value === investment?.target
        )[0]?.label,
      },
      dollar: 0,
      currency: "NGR",
      documents: [],
      document_links: "https://abc.test.com/",
      description: "",
      investment_memo: "",
    },
    validationSchema: createInvestmentSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (
      form?.values?.investment_category_id?.value === 2 ||
      form?.values?.investment_category_id?.value === 3 ||
      form?.values?.investment_category_id?.value === 11
    ) {
      setStartupInvesting(true);
    } else {
      setStartupInvesting(false);
    }
  }, [form?.values?.investment_category_id?.value]);

  return (
    <DashboardLayout>
      <div className="px-2 lg:px-8 ">
        <BackArrow2 mt={20} ml={0} />
        <div className="flex flex-col md:flex-row justify-between items-center mt-8 mb-12">
          <div className="mb-4 md:mb-0">
            <h1 className="font-semibold text-xl md:text-2xl">
              Modify {investment?.name}
            </h1>
          </div>
        </div>
        <div className="p-2">
          <div className="mb-4">
            <h1 className="font-[600] text-base">Investment Detail</h1>
          </div>
          <div className="flex flex-row gap-4">
            <div style={containerStyle}>
              <p>Investment Banner:</p>
              {myImagePreview ? (
                <div
                  className="w-[90%] lg:w-[90%]"
                  style={{
                    height: "130px",
                    border: "1px solid #EAECF0",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "10px",
                    textAlign: "center",
                    lineHeight: 1.8,
                    marginTop: "10px",
                    cursor: "pointer",
                  }}
                >
                  <IconButton
                    onClick={handleRemoveImage}
                    style={clearIconStyle}
                  >
                    <ClearIcon style={{ color: "white" }} />
                  </IconButton>
                  <img
                    src={myImagePreview}
                    alt="Preview"
                    className="object-cover"
                    style={{ width: "100%", height: "130px" }}
                  />
                </div>
              ) : (
                <div
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                  onClick={handleDivClick}
                  style={bannerStyle}
                >
                  <p className="text-sm mt-4">
                    <div className="flex items-center justify-center">
                      <img
                        src="/Assets/upload.png"
                        alt="Personal icon"
                        className="w-12 h-12 mr-4"
                      />
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        hidden
                        ref={fileInputRef}
                      />
                    </div>
                    <span className="text-[#159AA8] mt-2">
                      Click to upload{" "}
                    </span>
                    or drag and drop
                  </p>
                  <p className="text-sm">
                    SVG, PNG, JPG, or GIF (max. 800x400px)
                  </p>
                </div>
              )}
            </div>

            {!startupInvesting && (
              <div style={containerStyle}>
                <p>Investment Memo:</p>

                {documentsPreview ? (
                  <div
                    className="w-[90%] lg:w-[350px] mt-3 h-auto sm:!h-[165px]"
                    style={{
                      height: "130px",
                      border: "1px solid #EAECF0",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "10px",
                      textAlign: "center",
                      lineHeight: 1.8,
                      marginTop: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <IconButton
                      onClick={handleRemoveInvestmentMemo}
                      style={clearIconStyle}
                    >
                      <ClearIcon />
                    </IconButton>
                    <iframe
                      src={documentsPreview}
                      width="100%"
                      height="130px"
                    />
                  </div>
                ) : document ? (
                  <div
                    className="w-[90%] lg:w-[350px] mt-3 h-auto sm:!h-[165px]"
                    style={{
                      height: "130px",
                      border: "1px solid #EAECF0",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "10px",
                      textAlign: "center",
                      lineHeight: 1.8,
                      marginTop: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <IconButton
                      onClick={handleRemoveInvestmentMemo}
                      style={clearIconStyle}
                    >
                      <ClearIcon />
                    </IconButton>

                    {document?.name ? (
                      <span>{document?.name}</span>
                    ) : (
                      <iframe src={document} width="100%" height="130px" />
                    )}
                  </div>
                ) : (
                  <div
                    onDrop={handleMemoDrop}
                    onDragOver={handleMemoDragOver}
                    onClick={handleMemoDivClick}
                    style={bannerStyle}
                    className="mt-3 h-auto sm:!h-[165px]"
                  >
                    <p className="text-[12px] sm:text-sm mt-4">
                      <div className="flex items-center justify-center">
                        <img
                          src="/Assets/upload.png"
                          alt="Personal icon"
                          className="w-12 h-12 mr-4"
                        />
                        <input
                          type="file"
                          accept=".pdf, .doc, .docx"
                          onChange={handleMemoFileChange}
                          hidden
                          ref={memoFileInputRef}
                        />
                      </div>
                      <span className="text-[12px] sm:text-sm text-[#159AA8] mt-2">
                        Click to upload{" "}
                      </span>
                      or drag and drop
                    </p>
                    <p className="text-[12px] sm:text-sm">
                      DOC or PDF (max. 20MB)
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div>
          {startupInvesting && (
            <div style={containerStyle}>
              <p>Campaign Documents:</p>
              <div className="grid sm:grid-cols-4 gap-8">
                {documents &&
                  documents?.map((document, index) => (
                    <>
                      <div
                        key={index} // Add a unique key for each element in the map
                        className=" mt-3 h-auto sm:!h-[165px] relative"
                        style={{
                          height: "130px",
                          border: "1px solid #EAECF0",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "10px",
                          textAlign: "center",
                          lineHeight: 1.8,
                          marginTop: "10px",
                          cursor: "pointer",
                        }}
                      >
                        <div
                          onClick={() => handleRemoveMemo(index)}
                          style={clearIconStyle}
                          className="absolute !-right-5 !-top-5"
                        >
                          <ClearIcon />
                        </div>

                        {document?.name ? (
                          <span>{document.name}</span>
                        ) : (
                          <iframe src={document} width="100%" height="500px" />
                        )}
                      </div>
                    </>
                  ))}
                {documents?.length < 4 && (
                  <div
                    onDrop={handleMemoDrop}
                    onDragOver={handleMemoDragOver}
                    onClick={handleMemoDivClick}
                    style={bannerStyle}
                    className="mt-3 h-auto sm:!h-[165px]"
                  >
                    <p className="text-[12px] sm:text-sm mt-4">
                      <div className="flex items-center justify-center">
                        <img
                          src="/Assets/upload.png"
                          alt="Personal icon"
                          className="w-12 h-12 mr-4"
                        />
                        <input
                          type="file"
                          accept=".pdf, .doc, .docx"
                          onChange={handleMemoFileChange}
                          hidden
                          ref={memoFileInputRef}
                        />
                      </div>
                      {documents?.length === 0 ? (
                        <span className="text-[12px] sm:text-sm text-[#159AA8] mt-2">
                          Click to upload{" "}
                        </span>
                      ) : (
                        <span className="text-[12px] sm:text-sm text-[#159AA8] mt-2">
                          Add up to four (4) Memo{" "}
                        </span>
                      )}
                      or drag and drop
                    </p>
                    <p className="text-[12px] sm:text-sm">
                      DOC or PDF (max. 20MB)
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div>
          <div className="p-2 mt-10">
            <div className=" grid sm:grid-cols-3 mt-4   gap-5">
              <div className="mb-4 lg:mb:0 lg:mr-8 w-full ">
                <p className="">Investment Name:</p>
                <CustomInput
                  id="name"
                  width="100%"
                  form={form}
                  placeholder={"Enter Investment Name"}
                  height="12px"
                  mt="10"
                  mr="10px"
                  mb="10px"
                  borderRadius={"10px"}
                  className="mobile-input-width"
                />
              </div>
              <div className=" mb-4 lg:mb:0  lg:mr-8 w-full">
                <p className="mb-0">Investment Category</p>
                <SelectField
                  placeholder="Select Category"
                  id="investment_category_id"
                  form={form}
                  options={categories.map((item) => ({
                    ...item,
                    label: item.name,
                    value: item.id,
                  }))}
                  sx={{
                    width: "100%",
                    padding: "1px",
                    height: "44px",
                    borderRadius: "10px",
                    marginTop: "1px",
                  }}
                  className="mobile-select"
                />
              </div>
              {form.values?.investment_category_id?.value !== 2 &&
                form.values?.investment_category_id?.value !== 3 &&
                form?.values?.investment_category_id?.value !== 11 && (
                  <>
                    <div className="mb-4 lg:mb:0 lg:mr-8 w-full">
                      <p className="">Expected Duration</p>
                      <div className="flex flex-row">
                        <CustomInput
                          id="duration"
                          width="70px"
                          form={form}
                          placeholder={"Enter Duration"}
                          height="12px"
                          mr="10px"
                          mb="10px"
                          borderRadius={"10px"}
                          className="mobile-input-width"
                        />
                        <SelectField
                          placeholder="Select"
                          id="duration_type"
                          form={form}
                          value={{ label: "Months", value: "Months" }}
                          options={[{ label: "Months", value: "Months" }]}
                          sx={{
                            width: "100%",
                            padding: "1px",
                            height: "44px",
                            borderRadius: "10px",
                            marginTop: "0px",
                          }}
                          className="mobile-select"
                        />
                      </div>
                    </div>

                    <div className="mb-4 lg:mb:0   w-full">
                      <p className="">Expected ROI</p>
                      <CustomInput
                        id="roi"
                        width="100%"
                        form={form}
                        placeholder={"Enter Expected ROI"}
                        height="12px"
                        mt="10"
                        mr="10px"
                        borderRadius={"10px"}
                        className="mobile-input-width"
                      />
                    </div>
                  </>
                )}
              <div className="mb-4 lg:mb:0 lg:pl-0 lg:mr-8 w-full">
                <p className="">Minimum Amount</p>
                <CustomInput
                  id="minimum_amount"
                  width="100%"
                  form={form}
                  type="currency"
                  placeholder={"Enter Amount"}
                  height="12px"
                  mt="10"
                  mb="2px"
                  mr="10px"
                  borderRadius={"8px"}
                  className="mobile-input-width"
                />
              </div>
              <div className="mb-4 lg:mb:0 lg:pl-0 lg:mr-8 w-full">
                <p className="">Funding Goal:</p>
                <CustomInput
                  id="maximum_amount"
                  width="100%"
                  form={form}
                  placeholder={"Enter Amount"}
                  type="currency"
                  height="12px"
                  mt="10"
                  mb="2px"
                  mr="10px"
                  borderRadius={"10px"}
                  className="mobile-input-width"
                />
              </div>
              <div className="mb-4 lg:mb:0 lg:pl-0 lg:mr-8 w-full">
                <p className="">Minimum Amount</p>
                <CustomInput
                  id="minimum_amount"
                  width="100%"
                  form={form}
                  type="currency"
                  placeholder={"Enter Amount"}
                  height="12px"
                  mt="10"
                  mb="2px"
                  mr="10px"
                  borderRadius={"10px"}
                  className="mobile-input-width"
                />
              </div>

              <div className="  mb-4 lg:mb:0 w-full  ">
                <span>Currency</span>
                <SelectField
                  placeholder="Select Currency"
                  id="currency"
                  form={form}
                  options={currencyOptions}
                  sx={{
                    width: "100%",
                    padding: "1px",
                    height: "44px",
                    marginTop: "0px",
                    borderRadius: "10px",
                  }}
                  className="mobile-select"
                />
              </div>

              {(form.values?.investment_category_id?.value === 2 ||
                form.values?.investment_category_id?.value === 3 ||
                form?.values?.investment_category_id?.value === 11) && (
                <>
                  <div className=" ">
                    {form?.values?.investment_category_id?.value === 2 ? (
                      <>
                        <p className="w-[max-content]">
                          Expected Annual Rental Return
                        </p>
                        <CustomInput
                          id="annual_rental_return"
                          width="100%"
                          form={form}
                          type="number"
                          placeholder={"Enter Percentage"}
                          height="12px"
                          mt="10"
                          mr="10px"
                          borderRadius={"10px"}
                          // className="mobile-input-width"
                        />
                      </>
                    ) : form?.values?.investment_category_id?.value === 11 ? (
                      <>
                        <p className="w-[max-content]">
                          Minimum Expected Return
                        </p>
                        <CustomInput
                          id="annualized_target_return"
                          width="100%"
                          form={form}
                          type="number"
                          placeholder={"Enter Percentage"}
                          height="12px"
                          mt="10"
                          mr="10px"
                          borderRadius={"10px"}
                          // className="mobile-input-width"
                        />
                      </>
                    ) : (
                      <>
                        <p className="w-[max-content]">
                          Annualized Target Return
                        </p>
                        <CustomInput
                          id="annualized_target_return"
                          width="100%"
                          form={form}
                          type="number"
                          placeholder={"Enter Percentage"}
                          height="12px"
                          mt="10"
                          mr="10px"
                          borderRadius={"10px"}
                          // className="mobile-input-width"
                        />
                      </>
                    )}
                  </div>

                  {form.values?.investment_category_id?.value === 2 ? (
                    <div className=" ">
                      <p className="w-[max-content]">Property Type</p>
                      <SelectField
                        placeholder="Select Category"
                        id="property_type"
                        form={form}
                        options={[
                          { value: "Residential", label: "Residential" },
                          { value: "Commercial", label: "Commercial" },
                        ]}
                        sx={{
                          width: "100%",
                          padding: "1px",
                          height: "44px",
                          borderRadius: "10px",
                          marginTop: "1px",
                        }}
                        className="mobile-select"
                      />
                    </div>
                  ) : (
                    <div className=" ">
                      <p className="w-[max-content]">Liquidity</p>
                      <SelectField
                        placeholder="Select Category"
                        id="liquidity"
                        form={form}
                        options={[
                          { value: "Annual", label: "Annual" },
                          { value: "None", label: "None" },
                        ]}
                        sx={{
                          width: "100%",
                          padding: "1px",
                          height: "44px",
                          borderRadius: "10px",
                          marginTop: "1px",
                        }}
                        className="mobile-select"
                      />
                    </div>
                  )}

                  <div className=" ">
                    <p className="w-[max-content]">
                      Projected timeline
                      {form?.values?.investment_category_id?.value === 2
                        ? "(Years)"
                        : form?.values?.investment_category_id?.value === 11
                        ? "(days)"
                        : ""}
                    </p>

                    {form?.values?.investment_category_id?.value === 2 ? (
                      <CustomInput
                        id="projected_timeline_years"
                        width="100%"
                        type="number"
                        form={form}
                        placeholder={"Enter Duration"}
                        height="16px"
                        mt="10"
                        mr="10px"
                        borderRadius={"10px"}
                        // className="mobile-input-width"
                      />
                    ) : form?.values?.investment_category_id?.value === 11 ? (
                      <CustomInput
                        id="projected_timeline_days"
                        width="100%"
                        type="number"
                        form={form}
                        placeholder={"Enter Duration"}
                        height="16px"
                        mt="10"
                        mr="10px"
                        borderRadius={"10px"}
                        // className="mobile-input-width"
                      />
                    ) : (
                      <SelectField
                        placeholder="Select projected timeline"
                        id="projected_timeline"
                        form={form}
                        options={[
                          { value: "5 - 7 years", label: "5 - 7 years" },
                          { value: "7 - 10 years", label: "7 - 10 years" },
                        ]}
                        sx={{
                          width: "100%",
                          padding: "1px",
                          height: "44px",
                          borderRadius: "10px",
                          marginTop: "1px",
                        }}
                        className="mobile-select"
                      />
                    )}
                  </div>

                  {/* <div className=" ">
                    <p className="w-[max-content]">Projected timeline</p>
                    <SelectField
                      placeholder="Select projected timeline"
                      id="projected_timeline"
                      form={form}
                      options={[
                        { value: "5 - 7 years", label: "5 - 7 years" },
                        { value: "5 - 7 years", label: "7 - 10 years" },
                      ]}
                      sx={{
                        width: "100%",
                        padding: "1px",
                        height: "44px",
                        borderRadius: "10px",
                        marginTop: "1px",
                      }}
                      className="mobile-select"
                    />
                  </div> */}

                  <div className=" ">
                    {form?.values?.investment_category_id?.value === 2 ||
                    form?.values?.investment_category_id?.value === 11 ? (
                      <p className="w-[max-content]">Exit Windows</p>
                    ) : (
                      <p className="w-[max-content]">Annual Dividend</p>
                    )}
                    <SelectField
                      placeholder="Select Category"
                      id="annual_dividend"
                      form={form}
                      options={[
                        { value: "Yes", label: "Yes" },
                        { value: "No", label: "No" },
                      ]}
                      sx={{
                        width: "100%",
                        padding: "1px",
                        height: "44px",
                        borderRadius: "10px",
                        marginTop: "1px",
                      }}
                      className="mobile-select"
                    />
                  </div>
                </>
              )}

              {form.values?.investment_category_id?.value === 2 ? (
                <div className="mb-4 lg:mb:0  w-full lg:mr-8">
                  <p className="">Property location</p>
                  <CustomInput
                    id="property_location"
                    width="100%"
                    form={form}
                    // type="text"
                    placeholder={"Enter Property location"}
                    height="12px"
                    mt="10"
                    mr="10px"
                    borderRadius={"10px"}
                    // className="mobile-input-width"
                  />
                </div>
              ) : (
                <div className="mb-4 lg:mb:0  w-full ">
                  <p className="">Investment Sector</p>
                  <SelectField
                    placeholder="Select Sector"
                    id="sector"
                    form={form}
                    options={investmentSectorOptions}
                    sx={{
                      width: "100%",
                      padding: "1px",
                      height: "44px",
                      borderRadius: "10px",
                      marginTop: "1px",
                    }}
                    className="mobile-select"
                  />
                </div>
              )}

              {form.values?.investment_category_id?.value !== 2 &&
                form.values?.investment_category_id?.value !== 3 &&
                form?.values?.investment_category_id?.value !== 11 && (
                  <div className=" mb-4 lg:mb:0 w-full">
                    <p className="">Payment Frequency</p>
                    <SelectField
                      placeholder="Select Frequency"
                      id="payment_frequency"
                      form={form}
                      options={paymentFrequencyOptions}
                      sx={{
                        width: "100%",
                        padding: "1px",
                        height: "44px",
                        borderRadius: "10px",
                        marginTop: "0px",
                      }}
                      className="mobile-select"
                    />
                  </div>
                )}

              <div className="mb-4 lg:mb:0  w-full ">
                <label>Campaign Start Date</label>
                {/* <div
                  style={{
                    border: "1px solid #EAECF0",
                    width: "100%", // Set width to 100%
                    height: "44px",
                    marginTop: "4px",
                    marginRight: "20px",
                    borderRadius: "10px",
                  }}
                > */}
                <HalDatePicker
                  label="Start Date"
                  disablePast={true}
                  id={"campaign_start_date"}
                  form={form}
                  small
                  rightImage
                />
                {/* </div> */}
              </div>

              <div className="mb-4 lg:mb:0  ">
                <p className="">Campaign Duration</p>
                <CustomInput
                  id="campaign_duration"
                  width="100%"
                  form={form}
                  placeholder={"Enter Duration"}
                  height="12px"
                  mt="10"
                  mr="10px"
                  borderRadius={"10px"}
                  // className="mobile-input-width"
                />
              </div>

              <div className="mb-4 ">
                <p className="">Campaign Duration Type</p>
                <SelectField
                  placeholder="Select Frequency"
                  id="campaign_duration_type"
                  form={form}
                  options={campaignFrequencyOptions}
                  sx={{
                    width: "100%",
                    padding: "1px",
                    height: "44px",
                    borderRadius: "10px",
                    marginTop: "0px",
                  }}
                  className="mobile-select"
                />
              </div>

              <div className=" ">
                <p className="w-[max-content]">Payout Type</p>
                <SelectField
                  placeholder="Select Payout Type"
                  id="payout_type"
                  form={form}
                  options={paymentType}
                  sx={{
                    width: "100%",
                    padding: "1px",
                    height: "44px",
                    borderRadius: "10px",
                    marginTop: "1px",
                  }}
                  className="mobile-select"
                />
              </div>

              <div className="mb-4  ">
                <p className="">Risk Rating</p>
                <SelectField
                  placeholder="Select Rating"
                  id="risk_factor"
                  form={form}
                  options={riskOptions}
                  sx={{
                    width: "100%",
                    padding: "1px",
                    height: "44px",
                    borderRadius: "10px",
                    marginTop: "0px",
                  }}
                  className="mobile-select"
                />
              </div>

              <div className="mb-4 lg:mb:0  w-full ">
                <p className="">Publish Investment To:</p>
                <SelectField
                  id="target"
                  form={form}
                  options={publicCategoryOptions}
                  sx={{
                    width: "100%",
                    padding: "1px",
                    height: "44px",
                    borderRadius: "10px",
                  }}
                  className="mobile-select"
                />
              </div>
              {(form.values?.target?.value === "members" ||
                form.values?.target?.value === "everyone") && (
                <div className=" ">
                  <p className="">Admin fee for Members</p>
                  <CustomInput
                    id="member_admin_perc"
                    width="100%"
                    form={form}
                    type="number"
                    placeholder={"Enter Percentage"}
                    height="12px"
                    mt="10"
                    mr="10px"
                    borderRadius={"10px"}
                    // className="mobile-input-width"
                  />
                </div>
              )}
              {(form.values?.target?.value === "non_members" ||
                form.values?.target?.value === "everyone") && (
                <div className="mb-4 ">
                  <p className="">Admin fee for Non-members</p>
                  <CustomInput
                    id="non_member_admin_perc"
                    width="100%"
                    form={form}
                    type="number"
                    placeholder={"Enter Percentage"}
                    height="12px"
                    mt="10"
                    mr="10px"
                    borderRadius={"10px"}
                    // className="mobile-input-width"
                  />
                </div>
              )}

              <div className="mb-4 lg:mb:0 w-full">
                <p>Video Title</p>
                <CustomInput
                  id="video_title"
                  width="100%"
                  form={form}
                  placeholder={"Enter Video Title"}
                  height="12px"
                  mt="10"
                  mr="10px"
                  mb="10px"
                  borderRadius={"10px"}
                  className="mobile-input-width"
                />
              </div>

              <div className="mb-4 lg:mb:0 w-full">
                <p>Video Link</p>
                <CustomInput
                  id="video_link"
                  width="100%"
                  form={form}
                  placeholder={"Enter Video Link"}
                  height="12px"
                  mt="10"
                  mr="10px"
                  mb="10px"
                  borderRadius={"10px"}
                  className="mobile-input-width"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8 p-2">
          <h1>Campaign Description</h1>
          <div className="border-solid border border-gray-300 rounded-md overflow-hidden w-full ">
            <ReactQuill
              value={description}
              onChange={(value) => setDescription(value)}
              placeholder="Type here..."
            />
          </div>
        </div>

        <div className="flex justify-center lg:justify-end items-center">
          <CustomButton
            variant="outlined"
            customColor="#fff"
            children="Cancel"
            width="100px"
            height="45px"
            padding="10px"
            borderColor="#D0D5DD"
            margin="5px 5px"
            color="#000"
            onClick={() => navigate(-1)}
          />
          <CustomButton
            variant="contained"
            customColor="#159AA8"
            children="Update Investment"
            width="196px"
            height="45px"
            loading={loading}
            padding="10px"
            margin="15px 0"
            color="#fff"
            onClick={form.handleSubmit}
          />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default EditInvestment;
