import React from "react";
import { useSelector } from "react-redux";
import useCurrency from "../../hooks/useCurrency";
import { getCurrencySymbol } from "../../Helper/getCurrencySymbol";
import { Box } from "@mui/system";
import GridTable from "./gridTable";
import Loader from "../SharedLayouts/Loader";

function PaymentScheduleTable({ cycle }) {
  const [_currency, getAmount] = useCurrency();
  const loading = useSelector((state) => state?.investment?.pending);
  const _investment = useSelector((state) => state?.investment?.investment);
  const investment = _investment;
  const paymentSchedules = cycle
    ? cycle?.paymentSchedules?.expected_date?.map((item, index) => ({
        ...item,
        amount: `${
          getCurrencySymbol(investment?.currency) + getAmount(item?.amount, 2)
        }`,
        roi: Number(investment?.roi).toFixed(0) + "%",
        installment: "Payout " + (+index + 1),
      })) || []
    : investment?.paymentSchedules?.expected_date?.map((item, index) => ({
        ...item,
        amount: `${
          getCurrencySymbol(investment?.currency) + getAmount(item?.amount, 2)
        }`,
        roi: Number(investment?.roi).toFixed(0) + "%",
        installment: "Payout " + (+index + 1),
      })) || [];

  const columns = [
    { field: "repayment_type", headerName: "Repayment Type", width: 150 },
    { field: "installment", headerName: "Installment", width: 200 },
    { field: "amount", headerName: "Amount", width: 200 },
    { field: "date", headerName: "Due Date", width: 200 },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      renderCell: (params) => (
        <div
          className={
            params.row?.status === "Paid"
              ? `text-[#067647] bg-[#ecfdf3] p-[5px] w-[74px] text-center rounded-[14px]`
              : `text-[#b42318] bg-[#fecdca] p-[5px] w-[74px] text-center rounded-[14px]`
          }
        >
          {params.row?.status}
        </div>
      ),
    },
  ];

  return (
    <div className="bg-white p-2 rounded-lg ">
      {!loading ? (
        !paymentSchedules ? (
          <div className="  p-5 text-center">No data</div>
        ) : (
          <Box sx={{ overflowX: "auto", width: "100%", cursor: "pointer" }}>
            <GridTable
              type={"paymentSchedule"}
              rows={paymentSchedules}
              columns={columns}
              getRowId={(row) => row?.id}
              handleRowClick={() => {}}
            />
          </Box>
        )
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default PaymentScheduleTable;
