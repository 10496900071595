import React from "react";

const InvestmentCard = ({
  imageSrc,
  campaign,
  section1,
  section2,
  location,
  title,
  onClick,
  text,
}) => {
  return (
    <div
      onClick={onClick}
      className=" flex     flex-col rounded-[14px] justify-between items-start transition-all ease-in-out duration-100 border-[1.2px] cursor-pointer border-[#f0f0f5] px-4 pb-5 w-[339px] h-[273px]"
    >
      <div className="w-[42px] h-[42px] m-3 bg-[#FFF1DB] rounded-full overflow-hidden">
        {imageSrc ? (
          <img
            src={imageSrc}
            alt="icon"
            className=" w-full h-full  object-cover"
          />
        ) : (
          <></>
        )}
      </div>
      <div className="mt-[10px] w-full">
        {/* <div className="gap-[5px] flex rounded-[40px] mt-[15px] h-[32px] w-[140px] text-[10px] bg-[#F2F7F5] text-[#20847C] justify-center items-center font-[500]">
          <img
            src={"/Assets/campaign.png"}
            alt=""
            className="w-[16px] h-[16px]"
          />
          <span className="ml-[5px] ">{campaign} Campaign</span>
        </div> */}
        <div className="h-[0.5px] mt-[15px] w-full bg-[#F2F2F7]"></div>
        <div className="truncate mt-2 text-[14px] text-left text-[#131E3D] font-[400]">
          {title}
        </div>
        <div className="flex mt-[5px] items-center text-[#9C9C9E] text-[14px] font-[400]">
          <img
            src={"/Assets/location_on.png"}
            alt=""
            className="w-[16px] h-[16px]"
          />
          <span className="truncate ml-[5px]">{location}</span>
        </div>
        <div className="flex gap-[6px] mt-[10px]">
          <div className="max-w-[80%] truncate min-w-[81px] h-[24px] rounded-[40px] px-3 bg-[#f2f2f7] flex justify-center items-center text-[12px] font-[400] text-[#131E3D]">
            {section1}
          </div>
          {/* <div className="min-w-[81px] h-[22px] rounded-[40px] px-3 bg-[#f2f2f7] flex justify-center items-center text-[12px] font-[400] text-[#131E3D]">
            {section2}
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default InvestmentCard;
