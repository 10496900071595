import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../Helper/proccessErrorMessage";
import { API_INVESTMENT_TO_BUSINESS } from "../../../config/api";

export const addInvestmentToBusiness = createAsyncThunk(
  "investment/addtobusiness",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "PUT",
        path: `${API_INVESTMENT_TO_BUSINESS}${payload?.investment_product_id}`,
        data: payload.data,
      });
      payload.cb();
      return response?.data;
    } catch (error) {
      payload.failed();
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      return rejectWithValue(error.message);
    }
  }
);
