import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../Helper/proccessErrorMessage";
import { API_GET_BUSINESSES_PRODUCTS } from "../../../config/api";

export const getBusinessProducts = createAsyncThunk(
  "getBusinessProducts/get",
  async (id, { rejectWithValue, getState }) => {
    const currency = getState().dashboard.currency;

    try {
      const { data } = await Client({
        method: "GET",
        path: `${API_GET_BUSINESSES_PRODUCTS}${id}&currency=${currency}`,
      });

      return data?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      return rejectWithValue(error.message);
    }
  }
);
