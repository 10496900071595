import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const HalDatePicker = ({
  label,
  leftImage,
  rightImage,
  type,
  selectedDate,
  defaultStyles,
  blackBorder,
  form,
  disableFuture,
  disablePast,
  id,
  disabled,
  setActiveInput,
  small,
}) => {
  const handleDateChange = (date) => {
    form?.setFieldValue(id || "startedDate", date);
    setActiveInput && setActiveInput("others");
  };

  const error = form?.errors[id];
  const CustomDatePicker = ({
    setOpen,
    label,
    id,
    value,
    disabled,
    InputProps: { ref } = {},
    inputProps: { "aria-label": ariaLabel } = {},
  }) => {
    const processCalenderDate = () => {
      const _fullDate = new Date(value);
      const _date = _fullDate.getDate();
      const _month = _fullDate.getMonth() + 1;
      const _year = _fullDate.getFullYear();

      return `${_date < 10 ? "0" : ""}${_date}-${
        _month < 10 ? "0" : ""
      }${_month}-${_year}`;
    };

    return (
      <>
        {type === 2 ? (
          <div
            id={id}
            disabled={disabled}
            ref={ref}
            aria-label={ariaLabel}
            onClick={() => setOpen((prev) => !prev)}
            className={`date-container h-[52px] my-[6px] flex items-center   ${
              blackBorder && "input-border"
            } ${!small && "lg:min-w-[375px]"} w-full`}
            style={defaultStyles}
          >
            <div
              className="flex  h-full flex-col w-full "
              style={{ margin: leftImage && "0 16px" }}
            >
              <div className=" w-full h-full flex items-center ">
                <h4 className="text-sm">{processCalenderDate()}</h4>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "10%",
              }}
            >
              {rightImage && (
                <img
                  src="/Assets/Calendar.png"
                  alt="calender"
                  width="24px"
                  height="24px"
                />
              )}
            </div>
          </div>
        ) : (
          <div
            id={id}
            disabled={disabled}
            ref={ref}
            aria-label={ariaLabel}
            onClick={() => setOpen((prev) => !prev)}
            className={`date-container h-[58px] my-2 flex items-center   ${
              blackBorder && "input-border"
            } ${!small && "lg:min-w-[375px]"} w-full`}
            style={defaultStyles}
          >
            <div
              className="flex  h-full flex-col w-full "
              style={{ margin: leftImage && "0 16px" }}
            >
              <h4 className="v1-label w-full" htmlFor="date">
                {label}
              </h4>
              <div className=" w-full h-full ">
                <h4 className="text-sm" htmlFor="date">
                  {processCalenderDate()}
                </h4>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "10%",
              }}
            >
              {rightImage && (
                <img
                  src="/Assets/Calendar.png"
                  alt="calender"
                  width="24px"
                  height="24px"
                />
              )}
            </div>
          </div>
        )}
        {error && (
          <div className="text-red-500 font-Source-Sans-Pro mb-6 text-xs mt-[2px] ml-1">
            {error}
          </div>
        )}
      </>
    );
  };

  const Inner = (props) => {
    const [open, setOpen] = React.useState(false);
    return (
      <DatePicker
        slotProps={{ field: { setOpen } }}
        {...props}
        disabled={disabled}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        className="h-[158px] bg-[red]"
        sx={{ border: "none" }}
        slots={{
          field: CustomDatePicker,
        }}
      />
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Inner
        label={label}
        value={form?.values[id]}
        disableFuture={disableFuture}
        disablePast={disablePast}
        onChange={handleDateChange}
      />
    </LocalizationProvider>
  );
};

export default HalDatePicker;
