import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useCurrency from "../../hooks/useCurrency";
import { getCurrencySymbol } from "../../Helper/getCurrencySymbol";
import GridTable from "./gridTable";
import { Box } from "@mui/system";
import Loader from "../SharedLayouts/Loader";

function PayoutTable({ cycle }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [_currency, getAmount] = useCurrency();
  const [response, setResponse] = useState(null);
  const [rows, setRows] = useState([]);
  const loading = useSelector((state) => state?.investment?.pending);
  const payoutHistory =
    useSelector((state) => state?.investment?.payoutHistory) || [];
  const _investment = useSelector((state) => state?.investment?.investment);
  const investment = cycle ? cycle : _investment;

  useEffect(() => {
    if (!investment.payout_details) {
      setRows([]);
    } else {
      setRows(
        [investment.payout_details]?.map((history) => ({
          id: history.id,
          investors_count: history.investors_count,
          status: history.status,
          amount_disbursed: `${getCurrencySymbol(history.currency)}${getAmount(
            history.total_amount_disbursed,
            2
          )}`,
        }))
      );
    }
  }, [payoutHistory, response, setRows]);

  const columns = [
    { field: "id", headerName: "Transaction ID", width: 150 },
    {
      field: "amount_disbursed",
      headerName: "Paid Out Amount",
      type: "amount",
      width: 150,
    },
    {
      field: "investors_count",
      headerName: "No of Investors Paid",
      width: 200,
    },
    { field: "status", headerName: "Status", width: 150 },
  ];

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      {!loading ? (
        rows.length === 0 ? (
          <div className="p-5 text-center">No Payout History</div>
        ) : (
          <Box sx={{ overflowX: "auto", width: "100%", cursor: "pointer" }}>
            <GridTable
              searchPlaceholder={"Search by ID"}
              type={"payoutHistory"}
              rows={rows}
              columns={columns}
              getRowId={(row) => row?.id}
            />
          </Box>
        )
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default PayoutTable;
