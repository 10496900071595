import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../Helper/proccessErrorMessage";
import { API_EDIT_BUSINESS_PRODUCTS } from "../../../config/api";

export const editBusinessProduct = createAsyncThunk(
  "investmentProduct/edit",
  async ({ payload, id, cb, setSubmitting }, { rejectWithValue }) => {
    try {
      const { data } = await Client({
        method: "POST",
        path: `${API_EDIT_BUSINESS_PRODUCTS}${id}`,
        data: payload,
        contentType: "multipart/form-data",
        serializerConfig: { indexes: null },
      });
      toast.success("Cycle updated successfully");
      cb();
      setSubmitting(false);
      return data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      setSubmitting(false);

      return rejectWithValue(error.message);
    }
  }
);
