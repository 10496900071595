import React, { useEffect, useRef, useState } from "react";
import DashboardLayout from "../../components/SharedLayouts/DashboardLayout";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { createBusinessProductSchema } from "../../Helper/validationSchemas";
import { getCategories } from "../../store/actions/investment/getCategories";
import ".//Investment.css";
import BackArrow2 from "../../components/BackArrow2";
import { createBusinessProduct } from "../../store/actions/businesses/createBusinessProduct";
import CreateBusinessProductMain from "../../components/investment/CreateBusinessProductMain";
import { editBusinessProduct } from "../../store/actions/businesses/editBusinessProduct";
import dayjs from "dayjs";
import { getServerDate } from "../../config/dateformatter";

const CreateBusinessProduct = () => {
  const { state } = useLocation();

  const { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [investmentMemo, setInvestmentMemo] = useState(
    state?.cycle?.investment_memo || null
  );
  const [memoFiles, setMemoFiles] = useState([]);

  useEffect(() => {
    dispatch(getCategories());
  }, []);

  const handleSubmit = (values, { setSubmitting }) => {
    const formData = new FormData();

    if (memoFiles) {
      memoFiles.forEach((document) => {
        formData.append("documents[]", document);
      });
    }

    if (investmentMemo) {
      formData.append("document", investmentMemo);
      formData.append("investment_memo", investmentMemo);
    }

    if (values?.sendNotification) {
      formData.append("send_email", 1);
    }

    formData.append("currency", values?.currency?.label);
    formData.append("name", values?.name);
    formData.append("status", 1);
    form.setFieldValue("minimum_amount", values?.minimum_amount);
    formData.append("amount", values?.amount);
    formData.append("due_date", getServerDate(values?.due_date));
    if (values?.target?.value) {
      formData.append("target", values?.target?.value);
    }
    formData.append("repayment_type", values?.repayment_type?.value);
    // formData.append(
    //   "duration",
    //   `${values?.duration} week${values?.duration == 1 ? "" : "s"}`
    // );
    formData.append("duration", Number(values?.duration));
    formData.append("campaign_duration_type", `weeks`);
    formData.append("roi", values?.roi);
    formData.append("regular_admin_fee", values?.regular_admin_fee);
    formData.append("business_admin_fee", values?.business_admin_fee);
    formData.append("executive_admin_fee", values?.executive_admin_fee);
    formData.append("investment_id", state?.investment_id);

    if (id) {
      dispatch(
        editBusinessProduct({
          payload: formData,
          id,
          setSubmitting,
          cb: () => {
            navigate(-2);
          },
        })
      );
    } else {
      dispatch(
        createBusinessProduct({
          payload: formData,
          navigate,
          failed: () => {
            setSubmitting(false);
          },
        })
      );
    }
  };

  const form = useFormik({
    initialValues: {
      currency: "",
      amount: "",
      name: "",
      business_roi: "0",
      regular_roi: "0",

      regular_admin_fee: "0",
      business_admin_fee: "0",
      executive_admin_fee: "0",

      executive_roi: "0",

      repayment_type: "",
      duration: "",
      roi: "",
    },
    validationSchema: createBusinessProductSchema,

    onSubmit: handleSubmit,
  });

  console.log(form, "forms");
  useEffect(() => {
    if (state?.cycle) {
      const {
        amount,

        banner,
        business_admin_fee,
        currency,
        due_date,
        duration,
        executive_admin_fee,
        id,
        investment_id,
        investment_memo,
        name,

        regular_admin_fee,
        repayment_type,
        roi,
      } = state?.cycle;
      form.setValues({
        currency: { label: currency, value: currency },
        amount,
        name,
        regular_admin_fee,
        business_admin_fee,
        executive_admin_fee,
        investment_memo_url: investment_memo,
        repayment_type: { label: repayment_type, value: repayment_type },
        duration,
        roi,
        due_date: dayjs(due_date),
        target: { label: "Everyone", value: "everyone" },
      });
    }
  }, []);

  return (
    <DashboardLayout>
      <div className="px-2 lg:px-8  ">
        <div className="flex items-center mb-8 flex-row md:items-center">
          <Link to={"/dashboard"}>
            <img src="/Assets/bread.png" alt="" className="w-8 h-8 mr-2" />
          </Link>
          <img src="/Assets/chev.png" alt="" className="w-8 h-8 mr-2" />
          <div
            onClick={() => {
              navigate(-1);
            }}
            className="font-semibold cursor-pointer text-[#475467] text-base flex"
          >
            Business
          </div>
          <img src="/Assets/chev.png" alt="" className="w-8 h-8 md:mr-2" />
          <span className="font-semibold text-[#475467] text-base bg-[#F9FAFB] p-1 rounded-md md:w-[120px]">
            {id ? "Edit Cycle" : "Create Cycle"}
          </span>
        </div>

        <div className="flex flex-col md:flex-row justify-between items-center mt-8 mb-4 md:mb-6">
          <div className=" flex w-full">
            {id ? (
              <h1 className="font-semibold text-xl md:text-2xl">
                Edit {state?.name}
              </h1>
            ) : (
              <h1 className="font-semibold text-xl md:text-2xl">
                Add a Cycle to {state?.name}
              </h1>
            )}
          </div>
        </div>
        <BackArrow2 mb={20} ml={0} />

        <div>
          <CreateBusinessProductMain
            investmentMemo={investmentMemo}
            memoFiles={memoFiles}
            setInvestmentMemo={setInvestmentMemo}
            setMemoFiles={setMemoFiles}
            form={form}
          />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default CreateBusinessProduct;
