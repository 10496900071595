import React from "react";
import Loader from "../SharedLayouts/Loader";

const AuthButton = ({
  mainClasses,
  title,
  outline,
  height,
  bg,
  color,
  leftIcon,
  rightIcon,
  textStyles,
  onClick,
  loading,
  disabled,
}) => {
  return (
    <button
      onClick={onClick}
      className={`${
        outline
          ? "border-[#6935D3] bg-transparent text-[#6935D3]"
          : "bg-[#6935D3] border-transparent text-white"
      } border-[1.1px] border-solid rounded-[8px] mt-10 mb-[15px]  h-[45px]  font-bold py-2 px-4 focus:outline-none focus:shadow-outline shadow-custom ${mainClasses}`}
      type="button"
    >
      {loading ? <Loader /> : title}
    </button>
  );
};

export default AuthButton;
