import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CustomButton from "../Buttons/Button";
import { IconButton } from "@mui/material";
import { ClearIcon } from "@mui/x-date-pickers";
import { useDispatch, useSelector } from "react-redux";
import { updateTimeline } from "../../store/actions/investment/updateTimeline";
import { useFormik } from "formik";
import { updateTimelineSchema } from "../../Helper/validationSchemas";
import SelectField from "../inputs/SelectField";
import HalDatePicker from "../inputs/HalDatePicker";
import dayjs from "dayjs";
import { getServerDate } from "../../config/dateformatter";

const UpdatTimelineModal = ({ open, onClose, investment_id }) => {
  const dispatch = useDispatch();
  const handleSubmit = (values, { setSubmitting }) => {
    dispatch(
      updateTimeline({
        data: {
          status: values?.stage?.value,
          cycle_id: open?.id,
          investment_id: investment_id,
          date: getServerDate(form.values?.date),
        },
        onClose,
        setSubmitting,
      })
    );
  };
  const form = useFormik({
    initialValues: {
      stage: { label: "Funding Completed", value: 2 },
      date: dayjs(new Date()),
    },
    validationSchema: updateTimelineSchema,
    onSubmit: handleSubmit,
  });
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="custom-modal-title"
      aria-describedby="custom-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          maxHeight: "80%",
          overflowY: "scroll",
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: 24,
          p: 3,
        }}
      >
        <IconButton
          aria-label="close"
          sx={{
            position: "absolute",
            top: 19,
            right: 10,
          }}
          onClick={onClose}
        >
          <ClearIcon />
        </IconButton>
        <h2 className="font-[500] mt-5 mb-2">Update Timeline</h2>

        <div className=" mb-4 lg:mb:0  w-full">
          <SelectField
            placeholder="Select Stage"
            id="stage"
            form={form}
            options={[
              {
                value: 2,
                label: "Funding Completed",
              },
              {
                value: 3,
                label: "Goods Purchased",
              },
              {
                label: "Goods Sold to business",
                value: 4,
              },
              {
                label: "Profit Payout Started",
                value: 5,
              },
            ]}
            sx={{
              width: "100%",
              padding: "1px",
              height: "52px",
              borderRadius: "10px",
              marginTop: "1px",
            }}
            className="mobile-select"
          />
        </div>

        <HalDatePicker
          type={2}
          label="Date"
          id={"date"}
          disableFuture={true}
          form={form}
          small
          rightImage
          className="-mr-5"
        />
        <div style={{ display: "flex", alignItems: "center", mt: 2 }}>
          <CustomButton
            variant="contained"
            customColor="#159AA8"
            children="Update"
            width="100%"
            height="46px"
            padding="10px"
            margin="15px 0"
            color="#fff"
            disabled={form?.isSubmitting || !form?.isValid}
            loading={form?.isSubmitting}
            onClick={form.handleSubmit}
          />
        </div>
      </Box>
    </Modal>
  );
};

export default UpdatTimelineModal;
