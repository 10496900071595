import React, { useState } from "react";
import { timeformatter } from "../../config/dateformatter";
import useCurrency from "../../hooks/useCurrency";
import { useDispatch } from "react-redux";
import { editBusinessProduct } from "../../store/actions/businesses/editBusinessProduct";
import Loader from "../SharedLayouts/Loader";
import { useNavigate } from "react-router-dom";

const CampaignCard = ({
  title,
  dueDate,
  investment,
  item,
  refresh,
  tenure,
  payoutType,
  nReturn,
  status,
  id,
  paymentSchedules,
  onClickPayout,
  onClick,
  invested,
  totalAmount,
  currency,
  showTimelines,
}) => {
  const [_, getAmount] = useCurrency();
  const percentageInvested = ((invested / totalAmount) * 100).toFixed(1);

  const [submitting, setSubmitting] = useState();
  const dispatch = useDispatch();

  const onClickReOpen = () => {
    const formData = new FormData();
    setSubmitting(id);
    formData.append("status", 1);

    dispatch(
      editBusinessProduct({
        payload: formData,
        id,
        setSubmitting,
        cb: () => {
          refresh();
        },
      })
    );
  };
  const onClickClose = () => {
    const formData = new FormData();
    setSubmitting(id);
    formData.append("status", 0);

    dispatch(
      editBusinessProduct({
        payload: formData,
        id,
        setSubmitting,
        cb: () => {
          refresh();
        },
      })
    );
  };

  const navigate = useNavigate();

  const viewInvestment = () => {
    navigate(`/investment/${investment?.id}`, {
      state: { investment, cycle: item },
    });
  };
  return (
    <div className="mb-[20px] py-5 px-5 w-full max-w-[412px] min-h-[264px] rounded-[14px] border-[1px] shadow-sm border-[#F0F0F5]">
      <div className="text-[16px] font-[500] text-[#131E3D]">{title}</div>
      <div className="mt-[10px]">
        <div className="flex gap-[10px] flex-wrap items-center">
          <div className="  gap-[5px] flex items-center">
            <img
              src={"/Assets/duration.png"}
              alt=""
              className=" w-[14px] h-[14px] "
            />
            <div className="text-[12px] font-[400] text-[#9C9C9E]">
              Campaign Due {timeformatter(dueDate, "formal", false, true)}
            </div>
          </div>

          <div className="flex gap-[5px] items-center">
            <img
              src={"/Assets/duration.png"}
              alt=""
              className=" w-[14px] h-[14px] "
            />
            <div className="text-[12px] font-[400] text-[#9C9C9E]">
              {tenure?.includes("week")
                ? tenure
                : `${tenure} month${Number(tenure) === 1 ? "" : "s"}`}{" "}
              Tenure
            </div>
          </div>

          <div className="flex gap-[5px] items-center">
            <img
              src={"/Assets/returns.png"}
              alt=""
              className=" w-[14px] h-[14px] "
            />
            <div className="text-[12px] font-[400] text-[#20A43D]">
              {nReturn}% Net Return
            </div>
          </div>
        </div>
        <div className="flex gap-[5px] items-center mt-2">
          <img
            src={"/Assets/profit.png"}
            alt=""
            className="w-[14px] h-[14px] "
          />
          <div className="text-[12px] capitalize font-[400] text-[#9C9C9E]">
            {payoutType}
          </div>
        </div>
      </div>
      {/* <div className="mt-[40px]">
        <div className="text-[14px] text-[#131E3D] font-[400]">
          {percentageInvested}% invested of {currency}
          {getAmount(totalAmount)}
        </div>
        <div className="w-full bg-[#F2F2F7] h-[4px] rounded-full mt-2">
          <div
            className="bg-[#20A43D] h-full "
            style={{ width: `${percentageInvested}%` }}
          ></div>
        </div>
      </div> */}
      <div className="  flex items-center mt-[30px]">
        <div
          onClick={showTimelines}
          className=" flex gap-[5px] cursor-pointer items-center"
        >
          <img
            src={"/Assets/pace.png"}
            alt=""
            className=" w-[14px] h-[14px] "
          />
          <div className=" text-[14px] font-[400] text-[#6935D3] ">
            Update Campaign Timeline
          </div>
        </div>
      </div>
      <div className="  flex gap-5 items-center mt-[30px]">
        <button
          onClick={viewInvestment}
          className="  flex justify-center items-center bg-[#6935D3]  py-2 min-w-[80px] px-4 rounded"
        >
          <h3 className="text-[14px] font-[500] text-white ">View</h3>
        </button>
        {status == 1 || status === null ? (
          <button
            onClick={onClick}
            className="  flex justify-center items-center bg-[#6935D3]  py-2 min-w-[80px] px-4 rounded"
          >
            <h3 className="text-[14px] font-[500] text-white ">Start</h3>
          </button>
        ) : status == 0 || status == 6 ? (
          <button
            onClick={onClickReOpen}
            className="  flex justify-center cursor-pointer items-center bg-[#EF5364]  py-2 min-w-[80px] px-4 rounded"
          >
            {submitting === id ? (
              <img
                width="24px"
                height="24px"
                alt="Button Icon"
                src={"/Assets/loading.gif"}
              />
            ) : (
              <h3 className="text-[14px] font-[500] text-[#FFF] ">Re-Open</h3>
            )}
          </button>
        ) : (
          <></>
        )}

        {status == 1 || status == 2 ? (
          <button
            onClick={onClickClose}
            className="  flex justify-center cursor-pointer items-center bg-[#EF5364]  py-2 min-w-[80px] px-4 rounded"
          >
            {submitting === id ? (
              <img
                width="24px"
                height="24px"
                alt="Button Icon"
                src={"/Assets/loading.gif"}
              />
            ) : (
              <h3 className="text-[14px] font-[500] text-[#FFF] ">Close</h3>
            )}
          </button>
        ) : (
          <></>
        )}
        {!paymentSchedules ? (
          <button
            onClick={onClick}
            className="  flex justify-center items-center bg-[#6935D3]  py-2 min-w-[80px] px-4 rounded"
          >
            <h3 className="text-[14px] font-[500] text-white ">Start</h3>
          </button>
        ) : (
          <div
            onClick={onClickPayout}
            className="  flex justify-center cursor-pointer opacity-95  hover:opacity-100 items-center border-[1.1px] bg-[transpaprent] border-solid border-[#6935D3]  py-[7px] min-w-[80px] px-4 rounded"
          >
            <h3 className="text-[14px] font-[500] text-[#6935D3] ">Payout</h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default CampaignCard;
