import { Menu, Popover } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { moveInvestmentUp } from "../../store/actions/investment/moveInvestmentUp";

const InvestmentMenu = ({
  handleClose,
  anchorEl,
  selectedRow,
  handleAddToABusiness,
  ...props
}) => {
  const dispatch = useDispatch();
  const handleMoveUp = () => {
    dispatch(moveInvestmentUp({ data: selectedRow }));
    handleClose();
  };
  const handleMoveDown = () => {
    dispatch(moveInvestmentUp({ data: selectedRow }));
    handleClose();
  };

  return (
    <Menu {...props}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "180px",
          padding: 5,

          cursor: "pointer",
        }}
      >
        <span
          className=" font-[500] hover:bg-gray-100   p-3  hover:rounded-lg w-full"
          onClick={handleMoveUp}
        >
          Move Up
        </span>
        <span
          className=" font-[500] hover:bg-gray-100   p-3  hover:rounded-lg w-full"
          onClick={handleMoveDown}
        >
          Move down
        </span>
        <span
          className=" font-[500] hover:bg-gray-100   p-3  hover:rounded-lg w-full"
          onClick={handleAddToABusiness}
        >
          Add to a Business
        </span>
      </div>
    </Menu>
  );
};

export default InvestmentMenu;
