import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../Helper/proccessErrorMessage";
import { API_START_INVESTMENT } from "../../../config/api";
import { getInvestment } from "./getInvestment";
import { getAllProductInvestment } from "./getInvestments";

export const closeInvestment = createAsyncThunk(
  "investment/close",
  async ({ data, invID, callback }, { dispatch, rejectWithValue }) => {
    try {
      const response = await Client({
        method: "POST",
        path: API_START_INVESTMENT + "/" + invID,
        data,
      });
      toast.success("Investment closed successfully");
      callback();
      dispatch(getInvestment(data.id));
      dispatch(getAllProductInvestment());
      return response?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      return rejectWithValue(error.message);
    }
  }
);
