import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../Helper/proccessErrorMessage";
import {
  API_CREATE_BUSINESS_PRODUCTS,
  API_CREATE_BUSINESSES,
} from "../../../config/api";

export const createBusinessProduct = createAsyncThunk(
  "investmentProduct/create",
  async ({ payload, navigate, failed }, { rejectWithValue }) => {
    try {
      const { data } = await Client({
        method: "POST",
        path: API_CREATE_BUSINESS_PRODUCTS,
        data: payload,
        contentType: "multipart/form-data",
        serializerConfig: { indexes: null },
      });
      toast.success("Cycle created successfully");
      navigate(-1);
      // navigate(`/businesses/${payload?.investment_id}`, {
      //   state: {
      //     business: data?.data,
      //   },
      //   replace: true,
      // });
      return data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      failed();
      return rejectWithValue(error.message);
    }
  }
);
