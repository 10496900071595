import React, { useEffect, useState } from "react";
// import web from "/Assets/web.png";
// import calender from "/Assets/calender.png";
// import credit from "/Assets/credit.png";
// import sell from "/Assets/sell.png";
import { toast } from "react-toastify";
import { Parser } from "html-to-react";
import { formatAmount } from "../../config/utils";
import useCurrency from "../../hooks/useCurrency";
import { useNavigate } from "react-router-dom";
import AuthButton from "../Buttons/AuthButton";
import { useDispatch, useSelector } from "react-redux";
import AddCycleTypes from "../modal/AddCycleTypes";
import { getAllProductInvestment } from "../../store/actions/investment/getInvestments";
import AddCampaignTypes from "../modal/AddCampaignTypes";

const AboutInvesment = ({ business, refresh, businessProduct }) => {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const banner_link = business?.company_logo;
  const credit_rating = business?.credit_rating;
  const credit_rating_type = business?.credit_rating_type;
  const revenue_currency = business?.revenue_currency;
  const description = business?.description;
  const incorporation_date = business?.incorporation_date;
  const investment_memo = business?.investment_memo;
  const name = business?.name;
  const website = business?.website;
  const revenue = business?.revenue;
  const sector = business?.sector;
  const webLink = business?.website;
  const businessId = business?.id;
  const htmlParser = new Parser();
  const [_currency, getAmount] = useCurrency();
  const navigate = useNavigate();

  const { businessProducts } = useSelector((state) => state?.investment);

  return (
    <>
      <div className=" mb-[20px] w-full max-w-[540px] ">
        <div className="mb-[40px] w-full h-[397px] ">
          <img
            src={banner_link}
            alt=""
            className=" w-full h-[397px] rounded-[14px] object-cover"
          />
        </div>
        <div className=" flex mb-[10px] justify-between items-center ">
          <div className=" text-[16px] font-[500] text-[#131E3D] ">About</div>
          <div
            className={`cursor-pointer flex items-center gap-[5px] ${
              website?.includes("http") ? "" : "opacity-65 cursor-not-allowed"
            }`}
          >
            <a
              // onClick={() => {
              //   if (!opportunity?.investment_memo) {
              //     toast.error("Sorry! The document has not been uploaded.");
              //   }
              // }}
              rel="noreferrer"
              href={website?.includes("http") ? website : null}
              target="_blank"
              className=" text-[#6935D3] text-[16px] font-[400]"
            >
              Visit website
            </a>
            <img
              src={"/Assets/web.png"}
              alt=""
              className=" w-[20px] h-[20px] "
            />
          </div>
        </div>
        <div className=" h-[0.5px] w-full bg-[#F2F2F7] "></div>

        <div className="truncate mt-[10px]  text-[#9C9C9E] text-[16px] font-[400] ">
          {description && description !== "null"
            ? htmlParser?.parse(description)
            : ""}{" "}
        </div>

        <div className=" mt-[20px] flex gap-[8px] ">
          {/* <div className=" bg-[#F2F2F7] px-3 min-w-[99px] font-[400] text-[14px] text-[#131E3D]  h-[33px] rounded-[40px] p-4 flex items-center justify-center ">
          {opportunity?.investment_category?.name ||
            opportunity?.category?.name}
        </div> */}
          <div className=" bg-[#F2F2F7] px-3 min-w-[64px] font-[400] text-[14px] text-[#131E3D] h-[33px] rounded-[40px] p-4 flex items-center justify-center ">
            {sector}
          </div>
          {/* <div className=" bg-[#F2F2F7] px-3 min-w-[83px] font-[400] text-[14px] text-[#131E3D] h-[33px] rounded-[40px] p-4 flex items-center justify-center ">
          Farming{" "}
        </div> */}
        </div>

        <div className="  mx-auto w-full  ">
          <div className="flex mt-[40px]  justify-between">
            <div className="flex gap-[6px]  items-center">
              <img
                src={"/Assets/calender.png"}
                alt=""
                className=" w-[18px] h-[18px] "
              />
              <div className=" text-[16px] font-[400] text-[#9C9C9E] ">
                Incorporation Date
              </div>
            </div>
            <div className=" text-[#131E3D] text-[16px] font-[400] ">
              {incorporation_date || "--"}
            </div>
          </div>
          <div className=" mt-[10px] w-full bg-[#F2F2F7] h-[1.5px]  "></div>
        </div>

        <div className=" mx-auto w-full ">
          <div className="flex mt-[10px] items-center  justify-between">
            <div className=" flex gap-[6px] items-center ">
              <img
                src={"/Assets/credit.png"}
                alt=""
                className=" w-[18px] h-[18px] "
              />
              <div className=" text-[16px] font-[400] text-[#9C9C9E] ">
                Credit Rating
              </div>
            </div>
            <div className=" text-[#131E3D] text-[16px] font-[400] flex items-center">
              {credit_rating_type
                ? `${credit_rating}%[${credit_rating_type}]`
                : credit_rating || "--"}{" "}
            </div>
          </div>
          <div className=" mt-[10px] w-full bg-[#F2F2F7] h-[1.5px]  "></div>
        </div>

        <div className=" mx-auto w-full ">
          <div className="flex mt-[10px]  justify-between">
            <div className=" flex gap-[6px] items-center ">
              <img
                src={"/Assets/sell.png"}
                alt=""
                className="w-[18px] h-[18px] "
              />
              <div className=" text-[16px] font-[400] text-[#9C9C9E] ">
                {" "}
                Average Monthly Revenue
                {/* ( */}
                {/* {`${opportunity?.duration} ${
                Number(opportunity?.duration) < 2
                  ? opportunity?.duration_type?.slice(
                      0,
                      opportunity?.duration_type?.length - 1
                    )
                  : opportunity?.duration_type
              }`} */}
                {/* ) */}
              </div>
            </div>
            <div className=" text-[#131E3D] text-[16px] font-[400] ">
              {revenue_currency === "NGR" ? "₦" : "$"}
              {getAmount(Number(revenue))}{" "}
            </div>
          </div>
          <div className=" mt-[10px] w-full bg-[#F2F2F7] h-[1.5px]  "></div>
          <div className="flex gap-5 items-center">
            {businessProducts?.length > 0 ||
            businessProducts?.data?.length > 0 ? (
              <AuthButton
                title={"Add Cycle"}
                mainClasses={"!w-[47%] mt-0"}
                onClick={() => {
                  setShowModal(true);
                }}
              />
            ) : (
              <AuthButton
                title={"Add Campaign"}
                mainClasses={"!w-[47%] mt-0"}
                onClick={() => {
                  setShowModal(true);
                }}
              />
            )}
            <AuthButton
              disabled={true}
              title={"Edit"}
              outline={true}
              mainClasses={"!w-[47%] mt-0"}
              onClick={() => {
                navigate(`/edit-business/${businessId}`, {
                  state: {
                    business,
                  },
                });
              }}
            />
          </div>
        </div>
      </div>

      {showModal &&
        (businessProducts?.length > 0 || businessProducts?.data?.length > 0 ? (
          <AddCycleTypes
            open={showModal}
            refresh={refresh}
            name={name}
            businessProduct={businessProduct}
            onClose={() => setShowModal(false)}
            setShowAdminModal={setShowModal}
          />
        ) : (
          <AddCampaignTypes
            open={showModal}
            refresh={refresh}
            name={name}
            businessProduct={businessProduct}
            onClose={() => setShowModal(false)}
            setShowAdminModal={setShowModal}
            onProceed={() =>
              navigate(`/campaign/create`, {
                state: {
                  investment_id: businessId,
                },
              })
            }
          />
        ))}
    </>
  );
};

export default AboutInvesment;
