import React, { useRef, useState } from "react";
import CustomButton from "../Buttons/Button";
import CustomInput from "../inputs/CustomInput";
import SelectField from "../inputs/SelectField";
import {
  formatArray,
  paymentType,
  publicCampaignCategoryOptions,
} from "../../config/data";
import HalDatePicker from "../inputs/HalDatePicker";
import { IconButton } from "@mui/material";
import { ClearIcon } from "@mui/x-date-pickers";

const CreateBusinessProductMain = ({
  form,
  investmentMemo,
  memoFiles,
  setInvestmentMemo,
  setMemoFiles,
  type,
}) => {
  const handleMemoChange = async (e, startupInvesting) => {
    if (!e?.dataTransfer?.files[0] && !e?.target?.files[0]) {
      return;
    }
    const files = e?.dataTransfer?.files || e?.target?.files;

    if (startupInvesting) {
      if (memoFiles?.length < 4) {
        setMemoFiles((prevFiles) => [...prevFiles, files[0]]);
      }
    } else {
      setInvestmentMemo(files[0]);
    }
  };

  const memoFileInputRef = useRef(null);

  const handleMemoDivClick = () => {
    memoFileInputRef.current.click();
  };

  const handleRemoveMemo = (index, startupInvesting) => {
    if (startupInvesting) {
      const updatedFiles = [...memoFiles];
      updatedFiles.splice(index, 1);
      setMemoFiles(updatedFiles);
    } else {
      setInvestmentMemo(null);
    }
  };

  const handleMemoFileChange = (event, startupInvesting) => {
    setInvestmentMemo(event.target.files[0]);
    handleMemoChange(event, startupInvesting);
  };

  const handleMemoDrop = (e, startupInvesting) => {
    e.preventDefault();
    handleMemoChange(e, startupInvesting);
  };

  const handleMemoDragOver = (e) => {
    e.preventDefault();
  };

  const containerStyle = {
    width: "100%",
    marginTop: "20px",
    position: "relative",
  };

  const bannerStyle = {
    width: "100%",
    maxWidth: "590px",
    height: "auto",
    border: "1px solid #EAECF0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    justifyContent: "center",
    borderRadius: "10px",
    textAlign: "center",
    lineHeight: 1.8,
    cursor: "pointer",
  };

  const clearIconStyle = {
    position: "absolute",
    top: "0px",
    right: "10px",
    background: "#159AA8",
    borderRadius: "50%",
    padding: "4px",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
    cursor: "pointer",
  };

  return (
    <div>
      <div>
        {/* <div className="p-2"> */}
        <div className="grid sm:grid-cols-3 gap-5 mt-12 investment-col w-full">
          <div className="mb-4 lg:mb:0 lg:pl-0 lg:mr-8 w-full">
            <p className="">Name</p>
            <CustomInput
              id="name"
              width="100%"
              form={form}
              type="text"
              placeholder={"Enter Name"}
              height="12px"
              mt="10"
              mb="2px"
              mr="10px"
              borderRadius={"8px"}
              className="mobile-input-width"
            />
          </div>
          <div className="mb-4 lg:mb:0  w-full lg:mr-8">
            <p className="">Currency</p>
            <SelectField
              placeholder="Select currency"
              id="currency"
              form={form}
              options={formatArray(["NGR", "USD"])}
              sx={{
                width: "100%",
                padding: "1px",
                height: "52px",
                borderRadius: "10px",
                marginTop: "1px",
              }}
              className="mobile-select"
            />
          </div>

          <div className="mb-4 lg:mb:0 lg:pl-0 lg:mr-8 w-full">
            <p className="">Minimum Amount</p>
            <CustomInput
              id="minimum_amount"
              width="100%"
              form={form}
              type="currency"
              placeholder={"Enter Amount"}
              height="12px"
              mt="10"
              mb="2px"
              mr="10px"
              borderRadius={"8px"}
              className="mobile-input-width"
            />
          </div>
          <div className="mb-4 lg:mb:0 lg:pl-0 lg:mr-8 w-full">
            <p className="">Funding Goal (Amount)</p>
            <CustomInput
              id="amount"
              width="100%"
              form={form}
              type="currency"
              placeholder={"Enter Amount"}
              height="12px"
              mt="10"
              mb="2px"
              mr="10px"
              borderRadius={"8px"}
              className="mobile-input-width"
            />
          </div>

          <div className="mb-4 lg:mb:0   ">
            <label>Campaign Due Date</label>

            <HalDatePicker
              type={2}
              label="Due Date"
              id={"due_date"}
              form={form}
              small
              disablePast={true}
              rightImage
              className="-mr-5"
            />
          </div>

          <div className="mb-4 lg:mb:0  w-full lg:mr-8">
            <p className="">Repayment Type</p>
            <SelectField
              placeholder="Select Repayment Type"
              id="repayment_type"
              form={form}
              options={paymentType}
              sx={{
                width: "100%",
                padding: "1px",
                height: "52px",
                borderRadius: "10px",
                marginTop: "1px",
              }}
              className="mobile-select"
            />
          </div>
          <div className="mb-4 lg:mb:0 lg:pl-0 lg:mr-8 w-full">
            <p className="">
              Expected Duration (Month{form?.values?.duration === 1 ? "" : "s"})
            </p>
            <CustomInput
              id="duration"
              width="100%"
              form={form}
              type="number"
              placeholder={"Enter Duration"}
              // heightV2="12px"
              mt="10"
              mb="2px"
              mr="10px"
              borderRadius={"4px"}
              className="mobile-input-width"
            />
          </div>
          <div className="mb-4 lg:mb:0 lg:pl-0 lg:mr-8 w-full">
            <p className="">ROI</p>
            <CustomInput
              id="roi"
              width="100%"
              form={form}
              type="number"
              placeholder={"Enter ROI"}
              height="12px"
              mt="10"
              mb="2px"
              mr="10px"
              borderRadius={"4px"}
              className="mobile-input-width"
            />
          </div>
          <div className="">
            <p className="">Publish Investment To:</p>
            <SelectField
              id="target"
              form={form}
              options={publicCampaignCategoryOptions}
              sx={{
                width: "100%",
                padding: "1px",
                height: "52px",
                borderRadius: "10px",
              }}
              className="mobile-select"
            />
          </div>
          {form.values?.target?.value === "everyone" ||
          form.values?.target?.value === "regular" ? (
            <div className=" ">
              <p className="w-[max-content]">Regular Admin fee</p>
              <CustomInput
                id="regular_admin_fee"
                width="100%"
                form={form}
                type="number"
                placeholder={"Enter Percentage"}
                height="12px"
                mt="10"
                mr="10px"
                borderRadius={"4px"}
                // className="mobile-input-width"
              />
            </div>
          ) : (
            <></>
          )}
          {form.values?.target?.value === "everyone" ||
          form.values?.target?.value === "business" ? (
            <div className=" ">
              <p className="w-[max-content]">Business Admin fee</p>
              <CustomInput
                id="business_admin_fee"
                width="100%"
                form={form}
                type="number"
                placeholder={"Enter Percentage"}
                height="12px"
                mt="10"
                mr="10px"
                borderRadius={"4px"}
                // className="mobile-input-width"
              />
            </div>
          ) : (
            <></>
          )}
          {form.values?.target?.value === "everyone" ||
          form.values?.target?.value === "executives" ? (
            <div className=" ">
              <p className="w-[max-content]">Executive Admin fee</p>
              <CustomInput
                id="executive_admin_fee"
                width="100%"
                form={form}
                type="number"
                placeholder={"Enter Percentage"}
                height="12px"
                mt="10"
                mr="10px"
                borderRadius={"4px"}
                // className="mobile-input-width"
              />
            </div>
          ) : (
            <></>
          )}
        </div>
        <div style={containerStyle}>
          <p>Campaign Documents:</p>
          {form.values?.investment_category_id?.name === "Real Estate" ||
          form?.values?.investment_category_id?.name === "Startup Investing" ||
          form?.values?.investment_category_id?.name ===
            "Exchange Traded Commodities" ? (
            <div>
              {memoFiles?.length < 4 && (
                <div
                  onDrop={(e) => handleMemoDrop(e, true)}
                  onDragOver={handleMemoDragOver}
                  onClick={handleMemoDivClick}
                  style={bannerStyle}
                  className="mt-3 h-auto sm:!h-[165px]"
                >
                  <div>
                    <div className="flex items-center justify-center">
                      <img
                        src="/Assets/upload.png"
                        alt="Personal icon"
                        className="w-12 h-12 mr-4"
                      />
                      <input
                        type="file"
                        accept=".pdf, .doc, .docx"
                        onChange={(e) => handleMemoFileChange(e, true)}
                        hidden
                        className=" w-[350px] h-[60px] "
                        ref={memoFileInputRef}
                      />
                    </div>
                    {memoFiles?.length === 0 ? (
                      <span className="text-[12px] sm:text-sm text-[#159AA8] mt-2">
                        Click to upload{" "}
                      </span>
                    ) : (
                      <span className="text-[12px] sm:text-sm text-[#159AA8] mt-2">
                        Add up to four (4) Memo{" "}
                      </span>
                    )}
                    <span className="text-[12px] sm:text-sm mt-4">
                      or drag and drop
                    </span>
                  </div>
                  <p className="text-[12px] sm:text-sm">
                    DOC or PDF (max. 20MB)
                  </p>
                </div>
              )}
            </div>
          ) : investmentMemo ? (
            <div
              className="w-[90%] lg:w-[350px] mt-3 h-auto sm:!h-[165px]"
              style={{
                height: "130px",
                border: "1px solid #EAECF0",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "10px",
                textAlign: "center",
                lineHeight: 1.8,
                marginTop: "10px",
                cursor: "pointer",
              }}
            >
              <IconButton onClick={handleRemoveMemo} style={clearIconStyle}>
                <ClearIcon />
              </IconButton>
              <span>
                {investmentMemo.name || investmentMemo?.slice?.(0, 40)}
              </span>
            </div>
          ) : (
            <div
              onDrop={handleMemoDrop}
              onDragOver={handleMemoDragOver}
              onClick={handleMemoDivClick}
              style={bannerStyle}
              className="mt-3 h-auto sm:!h-[165px]"
            >
              <p className="text-[12px] sm:text-sm mt-4">
                <div className="flex items-center justify-center">
                  <img
                    src="/Assets/upload.png"
                    alt="Personal icon"
                    className="w-12 h-12 mr-4"
                  />
                  <input
                    type="file"
                    accept=".pdf, .doc, .docx"
                    onChange={handleMemoFileChange}
                    hidden
                    ref={memoFileInputRef}
                  />
                </div>
                <span className="text-[12px] sm:text-sm text-[#159AA8] mt-2">
                  Click to upload{" "}
                </span>
                or drag and drop
              </p>
              <p className="text-[12px] sm:text-sm">DOC or PDF (max. 20MB)</p>
            </div>
          )}
        </div>
      </div>

      <div className="mt-5 p-2" id="terms">
        <label style={{ display: "flex", alignItems: "center" }}>
          <input
            type="checkbox"
            id="sendNotification"
            checked={form?.values?.sendNotification}
            onChange={() => {
              form.setFieldValue(
                "sendNotification",
                form?.values?.sendNotification ? false : true
              );
            }}
            style={{ width: "24px", height: "24px", marginRight: "10px" }}
          />
          <span>Send email notification to target audience</span>
        </label>
      </div>

      <div className="flex justify-center lg:justify-end items-center">
        <CustomButton
          variant="contained"
          customColor="#159AA8"
          children="Submit"
          width="180px"
          height="45px"
          loading={form?.isSubmitting}
          buttonStyles={{ width: "140px", borderRadius: "4px" }}
          className={"!w-[140px]"}
          padding="10px"
          margin="15px 0"
          color="#fff"
          disabled={
            (form?.values?.target?.value === "everyone"
              ? !form?.values?.executive_admin_fee ||
                !form?.values?.regular_admin_fee ||
                !form?.values?.business_admin_fee
              : (form?.values?.target?.value === "executives" &&
                  !form?.values?.executive_admin_fee) ||
                (form?.values?.target?.value === "regular" &&
                  !form?.values?.regular_admin_fee) ||
                (form?.values?.target?.value === "business" &&
                  !form?.values?.business_admin_fee)) ||
            !form.isValid ||
            !form.dirty
          }
          onClick={form.handleSubmit}
        />
      </div>
    </div>
  );
};

export default CreateBusinessProductMain;
