import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";

const CustomInput = ({
  label,
  icon,
  iconPosition,
  form,
  id,
  heightV2,
  height,
  type,
  width,
  mr,
  ml,
  mb,
  p,
  borderRadius,
  placeholder,
  borderColor,
  onChange,
  className,
  mt,
  bg,
  ...props
}) => {
  const handleChange = (e) => {
    form.setFieldValue(id, e.target.value);
  };

  return (
    <div>
      {type === "currency" ? (
        <CurrencyTextField
          variant="outlined"
          value={form?.values[id]}
          currencySymbol={form?.values?.currency?.value === "NGR" ? "₦" : "$"}
          outputFormat="string"
          maximumValue={10000000000000}
          minimumValue={0}
          onChange={(event, value) =>
            onChange ? onChange : form.setFieldValue(id, value)
          }
        />
      ) : (
        <TextField
          min
          fullWidth
          label={label}
          autoComplete="off"
          type={type}
          placeholder={placeholder}
          value={form?.values[id]}
          onChange={(e) => (onChange ? onChange : handleChange(e))}
          error={form?.errors[id] ? true : false}
          helperText={form?.errors[id] || ""}
          {...props}
          sx={{
            height: "52px",
            "& input": {
              // height: height,
              width: width ? width : "100%",
              borderRadius: "18px", // Adjust this value as needed
              border: bg && "none",
              outline: bg ? "none" : "initial",
              marginTop: mt,
              "&:focus": {
                borderColor: "transparent",
              },
              background: bg || "transparent",
            },
            borderRadius: "18px",
            marginRight: mr,
            marginBottom: mb,
            marginLeft: ml,
            backgroundColor: bg || "transparent",
            outline: bg ? "none" : "initial",
            border: "none",
            padding: p,
          }}
          InputProps={{
            style: {
              borderRadius: borderRadius,
              padding: "1px 1px",
              width: width ? width : "100%",
            },
            inputProps: { min: 0 },
            startAdornment: iconPosition === "left" && (
              <InputAdornment
                position="start"
                sx={{ alignItems: "center", justifyContent: "center" }}
              >
                {icon}
              </InputAdornment>
            ),
            endAdornment: iconPosition === "right" && (
              <InputAdornment
                position="end"
                sx={{ alignItems: "center", justifyContent: "center" }}
              >
                {icon}
              </InputAdornment>
            ),
          }}
        />
      )}
    </div>
  );
};

export default CustomInput;
