import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import CustomInput from "../inputs/CustomInput";
import CustomButton from "../Buttons/Button";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { fundWalletSchema } from "../../Helper/validationSchemas";
import { fundWalletOTP } from "../../store/actions/userManagement/fundWalletOTP";
import { fundWalletConfirmation } from "../../store/actions/userManagement/fundWalletConfirmation";
import SelectField from "../inputs/SelectField";
import { currencyOptions, publicCategoryOptions } from "../../config/data";

function FundWalletModal({ open, onClose, userId, setShowFundWalletModal }) {
  const loading = useSelector((state) => state?.user?.pending);
  const [otp, setOtp] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const dispatch = useDispatch();

  const handleVerify = () => {
    if (showOtp) {
      const data = {
        id: userId,
        otp,
        amount: form.values?.amount,
        settledAmount: form.values?.amount,
        currency: form.values.currency?.value,
      };
      dispatch(fundWalletConfirmation({data, callback: () => setShowFundWalletModal(false)}))
        } else {
      dispatch(fundWalletOTP({ id: userId, callback }));
    }
  };

  const callback = () => {
    setShowOtp(true);
  };

  const form = useFormik({
    initialValues: {
      amount: "",
      currency: "",
    },
    onSubmit: handleVerify,
    validationSchema: fundWalletSchema,
  });
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="custom-modal-title"
      aria-describedby="custom-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: 24,
          p: 2,
        }}
      >
        <IconButton
          aria-label="close"
          sx={{
            position: "absolute",
            top: 19,
            right: 10,
          }}
          onClick={onClose}
        >
          <ClearIcon />
        </IconButton>

        <div className="w-full p-5" style={{ padding: "10px 20px" }}>
          <img
            src="/Assets/approveIcon.png"
            alt=""
            className="w-12 h-12 mb-5"
          />
          <div>
            <h1 className="font-semibold text-2xl mb-4">Fund Wallet</h1>
            <div className=" w-full items-center mr-4">
              <div className="mt-8">
                <label>Amount</label>
                <CustomInput
                  width="432px"
                  height="16px"
                  id="amount"
                  type={'currency'}
                  form={form}
                  placeholder={"Enter amount paid here"}
                  borderColor="#D0D5DD"
                  borderRadius={"5px"}
                  pr="14px"
                />
              </div>
              <div className="mt-5">
                <p className="">Currency:</p>
                <SelectField
                  id="currency"
                  form={form}
                  options={currencyOptions}
                  sx={{
                    width: "100%",
                    padding: "1px",
                    height: "44px",
                    borderRadius: "10px",
                  }}
                  className="mobile-select"
                />
              </div>
              {showOtp && (
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  inputType="password"
                  numInputs={6}
                  renderInput={(props) => <input {...props} />}
                  placeholder="----"
                  containerStyle={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "34px",
                    marginBottom: "18px",
                  }}
                  inputStyle={{
                    width: "50px",
                    height: "50px",
                    border: "1.5px solid #159AA8",
                    borderRadius: "10px",
                    fontWeight: "bold",
                    color: "#159AA8",
                    fontSize: "20px",
                    marginLeft: "6px",
                    marginRight: "6px",
                  }}
                />
              )}

              <div className="flex mt-6 w-full items-center">
                <CustomButton
                  variant="outlined"
                  customColor="#fff"
                  children="Cancel"
                  width="100%"
                  onClick={onClose}
                  height="50px"
                  borderColor="#D0D5DD"
                  margin="0px 25px 0px 0px"
                  color="#159AA8"
                  iconWidth="22px"
                />

                <CustomButton
                  variant="contained"
                  customColor="#159AA8"
                  loading={loading}
                  children="Continue"
                  width="100%"
                  height="50px"
                  padding="10px"
                  onClick={form.handleSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default FundWalletModal;
