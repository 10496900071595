import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../Helper/proccessErrorMessage";
import {
  API_CREATE_BUSINESSES,
  API_CREATE_PRODUCT_INVESTMENT,
  API_GET_ALL_PRODUCT_INVESTMENT,
} from "../../../config/api";

export const eidtBusinesses = createAsyncThunk(
  "investment/edit",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await Client({
        method: "POST",
        path: `${API_CREATE_BUSINESSES}/${payload?.id}`,
        data: payload.data,
        contentType: "multipart/form-data",
        serializerConfig: { indexes: null },
      });

      toast.success("Business Updated Successfully.");
      payload.navigate(-1);
      // payload.navigate(`/businesses/${payload?.id}`, {
      //   state: {
      //     business: data?.data,
      //   },
      // });
      return data;
    } catch (error) {
      payload.failed();
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      return rejectWithValue(error.message);
    }
  }
);
