import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CustomButton from "../Buttons/Button";
import { IconButton } from "@mui/material";
import { ClearIcon } from "@mui/x-date-pickers";
import { useSelector } from "react-redux";

function ConfirmModal({ open, onClose, handleDelete }) {
  const loading = useSelector((state) => state?.user?.pending) || false;

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="custom-modal-title"
      aria-describedby="custom-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 3,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <IconButton
          aria-label="close"
          sx={{
            position: "absolute",
            top: 19,
            right: 10,
          }}
          onClick={onClose}
        >
          <ClearIcon />
        </IconButton>
        <h2 className="font-[700] my-5">Confirm Delete</h2>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1 className="mb-4">
            Are you sure you want to permanently remove this item?{" "}
          </h1>
        </div>

        <div style={{ display: "flex", alignItems: "center", mt: 2 }}>
          <CustomButton
            variant="outlined"
            customColor="#fff"
            children="Cancel"
            width="100%"
            height="32px"
            padding="10px"
            margin="5px 20px 0px 0px"
            color="#000"
            onClick={onClose}
          />
          <CustomButton
            variant="contained"
            customColor="#159AA8"
            children="Delete"
            width="100%"
            height="32px"
            padding="10px"
            margin="15px 0"
            color="#fff"
            loading={loading}
            onClick={handleDelete}
          />
        </div>
      </Box>
    </Modal>
  );
}

export default ConfirmModal;
