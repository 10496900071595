import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  API_CLOSE_OUT_INVESTMENT,
  API_GET_ALL_PRODUCT_INVESTMENT,
  API_GET_PRODUCT_BY_DETAIL,
} from "../../../config/api";
import { getInvestment } from "./getInvestment";

export const closeOutInvetment = createAsyncThunk(
  "investment/closeOut",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await Client({
        method: "PUT",
        path: API_CLOSE_OUT_INVESTMENT + payload?.id,
      });

      toast.success("Cycle has been successfully closed");
      payload?.cb();

      return response?.data;
    } catch (error) {
      // const msg = proccessErrorMessage(error);
      toast.error(error);
      payload?.failed();
      return rejectWithValue(error.message);
    }
  }
);
