import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { API_DELETE_PRODUCT_INVESTMENT } from "../../../config/api";
import { getInvestment } from "./getInvestment";

export const deleteInvestment = createAsyncThunk(
  "investment/delete",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await Client({
        method: "DELETE",
        path: API_DELETE_PRODUCT_INVESTMENT + payload?.id,
      });
      dispatch(getInvestment(payload?.id));
      toast.success("The investment has been successfully deleted");

      return response?.data;
    } catch (error) {
      // const msg = proccessErrorMessage(error);
      toast.error(error);
      return rejectWithValue(error.message);
    }
  }
);
