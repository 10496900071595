import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import CustomButton from "../Buttons/Button";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch, useSelector } from "react-redux";
import SelectField from "../inputs/SelectField";
import { addPreviousRecords } from "../../store/actions/investment/addPreviousRecords";
import CustomInput from "../inputs/CustomInput";
import HalDatePicker from "../inputs/HalDatePicker";
import { useParams } from "react-router-dom";
import { getAllProductInvestment } from "../../store/actions/investment/getInvestments";
import { getServerDate } from "../../config/dateformatter";

function PreviousRecordsModal({ open, onClose, setOpenModal }) {
  const loading = useSelector((state) => state?.investment?.pending);
  const investment = useSelector((state) => state?.investment?.allInvestments);
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllProductInvestment());
  }, []);

  const handleSubmit = (values) => {
    const data = {
      ...values,
      investment_product_id: values?.investment_product_id?.value,
      payment_date: getServerDate(values?.payment_date),
    };

    dispatch(
      addPreviousRecords({ data, id, callback: () => setOpenModal(false) })
    );
  };

  const form = useFormik({
    initialValues: {
      investment_product_id: "",
      amount_invested: "",
      amount_paid: "",
      payment_date: null,
    },
    onSubmit: (values) => handleSubmit(values),
  });

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="custom-modal-title"
        aria-describedby="custom-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: 500,
            bgcolor: "background.paper",
            borderRadius: "10px",
            boxShadow: 24,
            p: 2,
          }}
        >
          <IconButton
            aria-label="close"
            sx={{
              position: "absolute",
              top: 19,
              right: 10,
            }}
            onClick={onClose}
          >
            <ClearIcon />
          </IconButton>

          <div className="w-full p-5">
            <img src="/Assets/approveIcon.png" alt="" className="w-12 mb-5" />
            <div>
              <h1 className="font-semibold sm:text-2xl mb-4">
                Add Previous Records
              </h1>
              <p className="text-sm mb-4 max-w-[432px]">
                Are you sure you want to add this record
              </p>
              <div className=" w-full items-center mr-4">
                <div className="mt-4">
                  <label>Select Investment</label>
                  <div className="mb-4">
                    <SelectField
                      id="investment_product_id"
                      options={investment?.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                      form={form}
                      className={"!h-[35px] md:h-[48px]"}
                      sx={{
                        maxWidth: "432px",
                        height: "48px",
                        borderRadius: "7px",
                        borderColor: "#D0D5DD",
                        color: "#101828",
                      }}
                      fw={400}
                      fz={"16px"}
                    />
                  </div>
                </div>

                <div className="mt-2">
                  <label>Amount Invested</label>
                  <CustomInput
                    height="16px"
                    id="amount_invested"
                    type={"currency"}
                    form={form}
                    placeholder={"Enter total amount invested"}
                    borderColor="#D0D5DD"
                    borderRadius={"5px"}
                    pr="10px"
                  />
                </div>
                <div className="mt-2">
                  <label>Amount Paid</label>
                  <CustomInput
                    height="16px"
                    type={"currency"}
                    id="amount_paid"
                    form={form}
                    placeholder={"Enter total amount paid"}
                    borderColor="#D0D5DD"
                    borderRadius={"5px"}
                    pr="14px"
                  />
                </div>
                <div className="flex mt-2">
                  <div className="flex flex-col mr-4">
                    <label className="mb-2">Payment Date</label>

                    <div
                      style={{
                        border: "1px solid #D0D5DD",
                        display: "flex",
                        flexDirection: "column",
                        borderRadius: "7px",
                        width: "100%",
                        maxWidth: "135px",
                        height: "50px",
                        borderRadius: "10px",
                      }}
                    >
                      <HalDatePicker
                        label="Date of Payment"
                        form={form}
                        id={"payment_date"}
                        rightImage
                      />
                    </div>
                  </div>
                </div>

                <div></div>
                <div className="flex flex-col mt-4 w-full items-center">
                  <div>
                    <CustomButton
                      variant="outlined"
                      customColor="#fff"
                      children="Cancel"
                      onClick={onClose}
                      width={{ sm: "210px" }}
                      height="50px"
                      borderColor="#D0D5DD"
                      color="#159AA8"
                      iconWidth="22px"
                    />
                  </div>
                  <div>
                    <CustomButton
                      variant="contained"
                      customColor="#159AA8"
                      loading={loading}
                      children="Continue"
                      width="210px"
                      height="50px"
                      margin="10px"
                      padding="10px"
                      onClick={form.handleSubmit}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default PreviousRecordsModal;
