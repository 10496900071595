import React from "react";
import UserTable from "../dashboard/user/UserTable";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getAllUser } from "../../store/actions/userManagement/management";
import { getInvestmentInterests } from "../../store/actions/investment/getInvestmentInterests";
import InvestmentInterestTable from "../dashboard/investment/InvestmentInterestTable";

function UserInterested({}) {
  const dispatch = useDispatch();
  const investment = useSelector((state) => state?.investment?.investment);

  return (
    <div>
      <div className="mt-10 ">
        <InvestmentInterestTable allUser={investment?.interested} />
      </div>
    </div>
  );
}

export default UserInterested;
