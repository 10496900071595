import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import CustomButton from "../Buttons/Button";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import SelectField from "../inputs/SelectField";
import { _countries } from "../../config/data";
import { toast } from "react-toastify";
import { addInvestmentToBusiness } from "../../store/actions/investment/addInvestmentToBusiness";
import { getBusinesses } from "../../store/actions/businesses/getBusinesses";
import { businessSchema } from "../../Helper/validationSchemas";

const AddInvestmentToBusiness = ({ open, selectedRow, onClose }) => {
  const dispatch = useDispatch();

  const { pending } = useSelector((state) => state?.investment);

  const { businesses } = useSelector((state) => state?.investment);

  const handleSubmit = (values, { setSubmitting }) => {
    const payload = {
      data: {
        business_id: values.business_id?.value,
      },
      investment_product_id: selectedRow?.id,
      failed: () => {
        setSubmitting(false);
      },
      cb: () => {
        form.resetForm();
        onClose();
        setSubmitting(false);
        toast.success("Investment Added to Business Successfully.");
      },
    };

    dispatch(addInvestmentToBusiness(payload));
  };

  const form = useFormik({
    initialValues: {
      business_id: null,
    },
    validationSchema: businessSchema,

    onSubmit: handleSubmit,
  });

  // useEffect(() => {
  //   if (investmentProductCurrency?.data)
  //     setInvestmentProductCurrency(investmentProductCurrency?.data);
  // }, [investmentProductCurrency?.data]);

  // useEffect(() => {
  //   if (currency) dispatch(getProductInvestmentByCurrency({ currency }));
  // }, [currency]);

  // useEffect(() => {
  //   setCurrency(form.values.currency);
  // }, [form]);
  useEffect(() => {
    dispatch(getBusinesses());
  }, []);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="custom-modal-title"
      aria-describedby="custom-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          maxHeight: "80%",
          overflowY: "scroll",
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: 24,
          p: 2,
        }}
      >
        <div className="w-full p-5" style={{ padding: "10px 20px" }}>
          <img
            src="/Assets/approveIcon.png"
            alt=""
            className="w-12 h-12 mb-5"
          />
          <div className="w-full  my-2">
            <label className="text-sm font-[600] text-[#344054]">
              Select investment
            </label>
            {pending ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  width="34px"
                  height="34px"
                  alt="Button Icon"
                  src={"/Assets/loading2.gif"}
                />
              </div>
            ) : (
              <div className="w-full mb-4">
                <SelectField
                  label="Choose Business"
                  id="business_id"
                  form={form}
                  options={
                    businesses
                      ? businesses?.map((item) => ({
                          value: item.id,
                          label: item.name,
                        }))
                      : []
                  }
                  sx={{ width: "100%", height: "50px" }}
                />
              </div>
            )}

            <div className="flex w-full  items-center">
              <CustomButton
                variant="contained"
                customColor="#159AA8"
                children="Continue"
                width="100%"
                height="45px"
                // disabled={
                //   !form.dirty ||
                //   form?.isSubmitting ||
                //   !form.values?.business_id?.value ||
                //   !form.isValid
                // }
                loading={form.isSubmitting}
                padding="10px"
                margin="15px 0"
                color="#fff"
                onClick={form.handleSubmit}
              />
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default AddInvestmentToBusiness;
