import React, { useEffect, useRef, useState } from "react";
import DashboardLayout from "../../components/SharedLayouts/DashboardLayout";
import CustomButton from "../../components/Buttons/Button";
import CustomInput from "../../components/inputs/CustomInput";
import SelectField from "../../components/inputs/SelectField";
import { IconButton } from "@mui/material";

import ClearIcon from "@mui/icons-material/Clear";
import {
  formatArray,
  investmentSectorOptions,
  revenueTypeOptions,
} from "../../config/data";
import HalDatePicker from "../../components/inputs/HalDatePicker";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { createBusinessSchema } from "../../Helper/validationSchemas";
import { getCategories } from "../../store/actions/investment/getCategories";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import ".//Investment.css";
import BackArrow2 from "../../components/BackArrow2";

import dayjs from "dayjs";
import { eidtBusinesses } from "../../store/actions/businesses/editBusinesses";
import { getServerDate } from "../../config/dateformatter";

const EditBusiness = () => {
  const { state } = useLocation();
  const business = state?.business;
  const { id } = useParams();

  const [banner, setBanner] = useState(null);

  const [sendMail, setSendMail] = useState(false);
  const [investmentMemo, setInvestmentMemo] = useState(null);

  const [memoFiles, setMemoFiles] = useState([]);

  const [description, setDescription] = useState(business?.description || "");
  const [busLogoPreview, setBusLogoPreview] = useState(null);
  const fileInputRef = useRef(null);
  const memoFileInputRef = useRef(null);
  const loading = useSelector((state) => state?.investment?.pending) || false;

  const categories =
    useSelector((state) => state?.investment?.categories) || [];

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getCategories());
  }, []);

  const handleFileChange = (event) => {
    setBanner(event.target.files[0]);
    handleImageChange(event);
  };

  const handleRemoveImage = () => {
    setBusLogoPreview(null);
    setBanner(null);
  };

  const handleMemoFileChange = (event, startupInvesting) => {
    setInvestmentMemo(event.target.files[0]);
    handleMemoChange(event, startupInvesting);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    handleImageChange(e);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleMemoDrop = (e, startupInvesting) => {
    e.preventDefault();
    handleMemoChange(e, startupInvesting);
  };

  const handleMemoDragOver = (e) => {
    e.preventDefault();
  };

  const containerStyle = {
    width: "100%",
    marginTop: "10px",
    position: "relative",
  };

  const handleImageChange = async (e) => {
    if (!e?.dataTransfer?.files[0] && !e?.target?.files[0]) {
      return;
    }
    const files = e?.dataTransfer?.files || e?.target?.files;
    const convertedFiles = files[0];

    const fileReader = new FileReader();
    fileReader.readAsDataURL(convertedFiles);
    fileReader.addEventListener("load", (result) => {
      setBusLogoPreview(result.target.result);
    });
    setBanner(convertedFiles);
  };

  const handleMemoChange = async (e, startupInvesting) => {
    if (!e?.dataTransfer?.files[0] && !e?.target?.files[0]) {
      return;
    }
    const files = e?.dataTransfer?.files || e?.target?.files;

    if (startupInvesting) {
      if (memoFiles?.length < 4) {
        setMemoFiles((prevFiles) => [...prevFiles, files[0]]);
      }
    } else {
      setInvestmentMemo(files[0]);
    }
  };

  const handleRemoveMemo = (index, startupInvesting) => {
    if (startupInvesting) {
      const updatedFiles = [...memoFiles];
      updatedFiles.splice(index, 1);
      setMemoFiles(updatedFiles);
    } else {
      setInvestmentMemo(null);
    }
  };

  const handleDivClick = () => {
    fileInputRef.current.click();
  };

  const handleMemoDivClick = () => {
    memoFileInputRef.current.click();
  };

  const handleAgreementChange = (event) => {
    setSendMail(event.target.checked);
  };

  const handleSubmit = (v, { setSubmitting }) => {
    const MAX_FILE_SIZE = 5120; // 5MB
    const bannerSizeKiloBytes = banner?.size / 1024;
    const documentSizeKiloBytes = investmentMemo?.size / 1024;

    if (bannerSizeKiloBytes > MAX_FILE_SIZE) {
      toast.error("Banner size is greater than the maximum limit");
      return;
    }
    if (documentSizeKiloBytes > MAX_FILE_SIZE) {
      toast.error("Document size is greater than the maximum limit");
      return;
    }

    const formData = new FormData();

    if (form.values?.name) {
      formData.append("name", form.values.name);
    }

    if (form.values?.website) {
      formData.append("website", form.values.website);
    }
    if (form.values?.revenue) {
      formData.append("revenue", form.values.revenue);
    }
    if (form.values?.location) {
      formData.append("location", form.values.location);
    }
    if (form.values?.credit_rating) {
      formData.append("credit_rating", form.values.credit_rating);
    }

    if (form.values?.currency?.value) {
      formData.append("revenue_currency", form.values.currency.value);
    }
    if (form.values?.revenue_type?.value) {
      formData.append("revenue_duration", form.values.revenue_type.value);
    }

    if (form.values?.credit_rating_type?.value) {
      formData.append(
        "credit_rating_type",
        form.values.credit_rating_type?.value
      );
    }
    if (form.values?.sector?.label !== undefined) {
      formData.append("sector", form.values?.sector?.label);
    }

    formData.append(
      "incorporation_date",
      getServerDate(form.values?.incorporation_date)
    );

    if (form.values?.investment_category_id?.value) {
      formData.append(
        "investment_category_id",
        form.values.investment_category_id.value
      );
    }

    if (form.values?.payout_type?.value !== undefined) {
      formData.append("payout_type", form.values?.payout_type?.value);
    }

    if (form.values?.campaign_duration !== undefined) {
      formData.append("campaign_duration", form.values?.campaign_duration);
    }

    if (form.values?.campaign_duration_type?.value) {
      formData.append(
        "campaign_duration_type",
        form.values?.campaign_duration_type?.value
      );
    }

    if (form.values?.document_links !== undefined) {
      formData.append("document_links", form.values?.document_links);
    }

    if (description) {
      formData.append("description", description);
    }

    // Add files if they exist
    if (banner) {
      formData.append("banner", banner);
      formData.append("company_logo", banner);
    }

    if (investmentMemo) {
      formData.append("document", investmentMemo);
      formData.append("investment_memo", investmentMemo);
    }

    dispatch(
      eidtBusinesses({
        data: formData,
        id,
        navigate,
        failed: () => {
          setSubmitting(false);
        },
      })
    );
  };

  const clearIconStyle = {
    position: "absolute",
    top: "0px",
    right: "10px",
    background: "#159AA8",
    borderRadius: "50%",
    padding: "4px",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
    cursor: "pointer",
  };

  const form = useFormik({
    initialValues: {
      name: business?.name,
      website: business?.website,
      revenue: business?.revenue,
      location: business?.location,
      credit_rating: business?.credit_rating,
      credit_rating_type: {
        label: business?.credit_rating_type,
        value: business?.credit_rating_type,
      },
      currency: {
        label: business?.revenue_currency,
        value: business?.revenue_currency,
      },
      revenue_type: {
        label: business?.revenue_duration,
        value: business?.revenue_duration,
      },
      sector: { label: business?.sector, value: business?.sector },
      incorporation_date: dayjs(business?.incorporation_date),

      description: business?.description,
    },
    validationSchema: createBusinessSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    setBusLogoPreview(business?.company_logo);
  }, []);

  return (
    <DashboardLayout>
      <div className="px-2 lg:px-8  ">
        <div className="flex items-center mb-8 flex-row md:items-center">
          <Link to={"/dashboard"}>
            <img src="/Assets/bread.png" alt="" className="w-8 h-8 mr-2" />
          </Link>
          <img src="/Assets/chev.png" alt="" className="w-8 h-8 mr-2" />
          <Link
            to="/businesses"
            className="font-semibold text-[#475467] text-base flex"
          >
            Business
          </Link>
          <img src="/Assets/chev.png" alt="" className="w-8 h-8 md:mr-2" />
          <span className="font-semibold text-[#475467] text-base bg-[#F9FAFB] p-1 rounded-md md:w-[100px]">
            Edit
          </span>
        </div>

        <div className="flex flex-col md:flex-row justify-between items-center mt-8 mb-4 md:mb-6">
          <div className=" flex w-full">
            <h1 className="font-semibold text-xl md:text-2xl">
              Edit {business?.name}
            </h1>
          </div>
        </div>
        <BackArrow2 mb={20} ml={0} />

        <div className="p-2">
          <div className="grid grid-cols-2 gap-5 sm:gap-10">
            <div style={containerStyle}>
              <p>Business Logo:</p>
              {busLogoPreview ? (
                <div
                  className="w-[90%] lg:w-[90%] !h-[165px] "
                  style={{
                    height: "10px",

                    border: "1px solid #EAECF0",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "10px",
                    textAlign: "center",
                    lineHeight: 1.8,
                    marginTop: "10px",
                    cursor: "pointer",
                  }}
                >
                  <IconButton
                    onClick={handleRemoveImage}
                    style={clearIconStyle}
                  >
                    <ClearIcon style={{ color: "white" }} />
                  </IconButton>
                  <img
                    src={busLogoPreview}
                    alt="Preview"
                    className="object-cover"
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
              ) : (
                <div
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                  onClick={handleDivClick}
                  className="mt-3 h-auto sm:!h-[165px] border-[1px] flex flex-col items-center rounded-[10px] text-center leading-[1.8] cursor-pointer justify-center p-[20px] border-[#EAECF0] w-[100%] max-w-[400px]  "
                >
                  <p className="text-sm mt-4">
                    <div className="flex items-center justify-center  ">
                      <img
                        src="/Assets/upload.png"
                        alt="Personal icon"
                        className="w-12 h-12 "
                      />
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        hidden
                        ref={fileInputRef}
                        className="  "
                      />
                    </div>
                    <span className="text-[#159AA8] mt-2">
                      Click to upload{" "}
                    </span>
                    or drag and drop
                  </p>
                  <p className="text-sm">
                    SVG, PNG, JPG, or GIF (max. 800x400px)
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>

        <div>
          {/* <div className="p-2"> */}
          <div className="grid sm:grid-cols-3 gap-5 mt-12 investment-col w-full">
            <div className="mb-4 lg:mb:0  w-full ">
              <p className="">Business Name:</p>

              <CustomInput
                id="name"
                width="100%"
                form={form}
                placeholder={"Enter Business Name"}
                height="16px"
                mt="10"
                mr="10px"
                mb="10px"
                borderRadius={"4px"}
                className="mobile-input-width"
              />
            </div>

            <div className="mb-4 lg:mb:0  w-full ">
              <p className="">Website:</p>
              <CustomInput
                id="website"
                width="100%"
                form={form}
                placeholder={"Enter website url"}
                height="16px"
                mt="10"
                mr="10px"
                mb="10px"
                borderRadius={"4px"}
                className="mobile-input-width"
              />
            </div>
            <div className="mb-4 lg:mb:0  w-full lg:mr-8">
              <p className="">Location</p>
              <CustomInput
                id="location"
                width="100%"
                form={form}
                // type="text"
                placeholder={"Enter location"}
                height="12px"
                mt="10"
                mr="10px"
                borderRadius={"4px"}
                // className="mobile-input-width"
              />
            </div>
            <div className="mb-4 lg:mb:0  w-full lg:mr-8">
              <p className="">Credit Rating</p>
              <CustomInput
                id="credit_rating"
                width="100%"
                form={form}
                type="number"
                placeholder={"Enter Credit Rating"}
                height="12px"
                mt="10"
                mr="10px"
                borderRadius={"4px"}
                // className="mobile-input-width"
              />
            </div>

            <div className="mb-4 lg:mb:0  w-full lg:mr-8">
              <p className="">Credit Rating Type</p>
              <SelectField
                placeholder="Select"
                id="credit_rating_type"
                form={form}
                options={[
                  { value: "A1", label: "A1" },
                  { value: "B1", label: "B1" },
                  { value: "A2", label: "A2" },
                  { value: "B2", label: "B2" },
                ]}
                sx={{
                  width: "100%",
                  padding: "1px",
                  height: "52px",
                  borderRadius: "10px",
                  marginTop: "1px",
                }}
                className="mobile-select"
              />
            </div>
            <div className="mb-4 lg:mb:0  w-full lg:mr-8">
              <p className="">Sector</p>
              <SelectField
                placeholder="Select Sector"
                id="sector"
                form={form}
                options={investmentSectorOptions}
                sx={{
                  width: "100%",
                  padding: "1px",
                  height: "52px",
                  borderRadius: "10px",
                  marginTop: "1px",
                }}
                className="mobile-select"
              />
            </div>
            <div className="mb-4 lg:mb:0   ">
              <label>Incorporation Date</label>

              <HalDatePicker
                type={2}
                label="Incorporation Date"
                id={"incorporation_date"}
                disableFuture={true}
                form={form}
                small
                rightImage
                className="-mr-5"
              />
            </div>
            <div className="mb-4 lg:mb:0  w-full lg:mr-8">
              <p className="">Revenue Duration</p>
              <SelectField
                placeholder="Select Type"
                id="revenue_type"
                form={form}
                options={revenueTypeOptions}
                sx={{
                  width: "100%",
                  padding: "1px",
                  height: "52px",
                  borderRadius: "10px",
                  marginTop: "1px",
                }}
                className="mobile-select"
              />
            </div>
            <div className="mb-4 lg:mb:0  w-full lg:mr-8">
              <p className="">Revenue Currency</p>
              <SelectField
                placeholder="Select Type"
                id="currency"
                form={form}
                options={formatArray(["NGR", "USD"])}
                sx={{
                  width: "100%",
                  padding: "1px",
                  height: "52px",
                  borderRadius: "10px",
                  marginTop: "1px",
                }}
                className="mobile-select"
              />
            </div>
            <div className="mb-4 lg:mb:0 lg:pl-0 lg:mr-8 w-full">
              <p className="">Revenue</p>
              <CustomInput
                id="revenue"
                width="100%"
                form={form}
                type="currency"
                placeholder={"Enter Revenue"}
                height="12px"
                mt="10"
                mb="2px"
                mr="10px"
                borderRadius={"4px"}
                className="mobile-input-width"
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-5 sm:gap-10">
          {memoFiles?.length > 0 ? (
            <div style={containerStyle}>
              {memoFiles?.map((file, index) => (
                <div key={index} className="mb-2">
                  <IconButton
                    onClick={() => handleRemoveMemo(index, true)}
                    style={clearIconStyle}
                  >
                    <ClearIcon />
                  </IconButton>
                  <span>{file.name}</span>
                </div>
              ))}
            </div>
          ) : (
            <></>
          )}
          {/* <div style={containerStyle}>
            <p>Banner:</p>
            {investmentMemo ? (
              <div
                className="w-[90%] lg:w-[350px] mt-3 h-auto sm:!h-[165px]"
                style={{
                  height: "130px",
                  border: "1px solid #EAECF0",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "10px",
                  textAlign: "center",
                  lineHeight: 1.8,
                  marginTop: "10px",
                  cursor: "pointer",
                }}
              >
                <IconButton onClick={handleRemoveMemo} style={clearIconStyle}>
                  <ClearIcon />
                </IconButton>
                <span>{investmentMemo.name}</span>
              </div>
            ) : (
              <div
                onDrop={handleMemoDrop}
                onDragOver={handleMemoDragOver}
                onClick={handleMemoDivClick}
                className="mt-3 h-auto sm:!h-[165px] border-[1px] flex flex-col items-center rounded-[10px] text-center leading-[1.8] cursor-pointer justify-center p-[20px] border-[#EAECF0] w-[100%] max-w-[400px]  "
              >
                <p className="text-[12px] sm:text-sm mt-4">
                  <div className="flex items-center justify-center">
                    <img
                      src="/Assets/upload.png"
                      alt="Personal icon"
                      className="w-12 h-12 mr-4"
                    />
                    <input
                      type="file"
                      accept=".pdf, .doc, .docx"
                      onChange={handleMemoFileChange}
                      hidden
                      ref={memoFileInputRef}
                    />
                  </div>
                  <span className="text-[12px] sm:text-sm text-[#159AA8] mt-2">
                    Click to upload{" "}
                  </span>
                  or drag and drop
                </p>
                <p className="text-[12px] sm:text-sm">DOC or PDF (max. 20MB)</p>
              </div>
            )}
          </div> */}
        </div>

        <div className="mt-8 p-2">
          <h1>Business Description</h1>

          <div className="border-solid border border-gray-300 rounded-md overflow-hidden w-full">
            <ReactQuill
              value={description}
              onChange={(value) => setDescription(value)}
              placeholder="Type here..."
            />
          </div>
        </div>

        <div className="flex justify-center lg:justify-end items-center">
          <CustomButton
            variant="contained"
            customColor="#159AA8"
            children="Update"
            width="140px"
            height="45px"
            loading={form?.isSubmitting}
            buttonStyles={{ width: "140px" }}
            className={"!w-[140px]"}
            padding="10px"
            margin="15px 0"
            color="#fff"
            disabled={!form.isValid}
            onClick={form.handleSubmit}
          />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default EditBusiness;
