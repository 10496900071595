import React, { useState } from "react";
import PaymentScheduleTable from "../table/PaymentScheduleTable";
import SearchIcon from "../Icons/SearchIcon";
import PayoutModal from "../modal/PayoutModal";
import { useNavigate, useParams } from "react-router-dom";
import DividendModal from "../modal/DividendModal";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../Buttons/Button";
import { closeOutInvetment } from "../../store/actions/investment/closeOutInvetment";

function PaymentSchedule({ cycle }) {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [openDividendModal, setOpenDividendModal] = useState(false);
  const investment = useSelector((state) => state?.investment?.investment);
  const user = useSelector((state) => state.onboarding.user);

  const { id } = useParams();
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleCloseDividendModal = () => {
    setOpenDividendModal(false);
  };
  const navigate = useNavigate();

  const [loadingCloseOut, setLoadingCloseOut] = useState(false);
  const handleClose = (id) => {
    setLoadingCloseOut(true);
    dispatch(
      closeOutInvetment({
        id,
        cb: () => {
          setLoadingCloseOut(false);
          // navigate(-1);
        },
        failed: () => {
          setLoadingCloseOut(false);
        },
      })
    );
  };

  return (
    <div>
      <div className="flex justify-between mb-8">
        <div
          className="search-wrapper hidden lg:flex items-center "
          style={{ width: "400px", height: "44px" }}
        >
          <SearchIcon />
          <input placeholder="Search for users" />
        </div>

        {/* <div
          className="border p-2 rounded-lg shadow-sm bg-white w-12"
          style={{ width: "97px", cursor: "pointer" }}
        >
          <div className="flex items-center ">
            <img
              src="/Assets/filter-lines.png"
              alt=""
              className="w-4 h-6 mr-2"
            />
            <span className="font-[600]">Filters</span>
          </div>
        </div> */}
      </div>
      <div className="bg-white p-6 rounded-lg shadow-md ">
        <div className="flex flex-row items-center justify-between">
          <h1 className="mb-4 font-[600]"> Payment Schedule</h1>

          {cycle?.id ? (
            <CustomButton
              variant="contained"
              customColor="#159AA8"
              children="Close Out"
              width="250px"
              height="40px"
              padding="10px"
              margin="15px 0"
              color="#fff"
              loading={loadingCloseOut}
              onClick={() => handleClose(cycle?.id)}
            />
          ) : (
            <></>
          )}
          {user?.roles &&
            !user?.roles?.some((role) => role.name === "Admin_Tier_1") && (
              <div
                className="border p-2 rounded-lg cursor-pointer shadow-sm bg-white mb-5 md:w-12 mr-2"
                onClick={() =>
                  investment?.investment_category?.name === "Startup Investing"
                    ? setOpenDividendModal(true)
                    : setOpenModal(true)
                }
                style={{ width: "102px", height: "42px" }}
              >
                <div className="flex items-center ">
                  <img
                    src="/Assets/dollar.png"
                    alt=""
                    className="w-4 h-5 mr-2"
                  />
                  <span className="font-[600]">Payout</span>
                </div>
              </div>
            )}
        </div>

        <PaymentScheduleTable cycle={cycle} />
      </div>
      <PayoutModal
        open={openModal}
        invID={id}
        cycle={cycle}
        cycle_id={cycle?.id}
        onClose={handleCloseModal}
        setOpenModal={setOpenModal}
      />
      <DividendModal
        open={openDividendModal}
        invID={id}
        onClose={handleCloseDividendModal}
        setOpenModal={setOpenDividendModal}
      />
    </div>
  );
}

export default PaymentSchedule;
