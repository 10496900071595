import React from "react";
import PayoutTable from "../table/PayoutTable";
import SearchIcon from "../Icons/SearchIcon";

function PayoutHistory({ cycle }) {
  return (
    <div>
      <div className="flex justify-between mb-8">
        <div
          className="search-wrapper hidden lg:flex items-center "
          style={{ width: "400px", height: "44px" }}
        >
          <SearchIcon />
          <input placeholder="Search Payout" />
        </div>

        <div
          className="border p-2 rounded-lg shadow-sm bg-white w-12"
          style={{ width: "97px", cursor: "pointer" }}
        >
          <div className="flex items-center ">
            <img
              src="/Assets/filter-lines.png"
              alt=""
              className="w-4 h-6 mr-2"
            />
            <span className="font-[600]">Filters</span>
          </div>
        </div>
      </div>

      <PayoutTable cycle={cycle} />
    </div>
  );
}

export default PayoutHistory;
