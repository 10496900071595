import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../Helper/proccessErrorMessage";
import {
  API_CREATE_BUSINESSES,
  API_CREATE_PRODUCT_INVESTMENT,
  API_GET_ALL_PRODUCT_INVESTMENT,
} from "../../../config/api";
import { replace } from "formik";

export const createBusinesses = createAsyncThunk(
  "investment/create",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "POST",
        path: API_CREATE_BUSINESSES,
        data: payload.data,
        contentType: "multipart/form-data",
        serializerConfig: { indexes: null },
      });
      toast.success("Business created successfully");
      payload.navigate("/businesses", { replace: true });
      return response?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      return rejectWithValue(error.message);
    }
  }
);
