import { createSlice } from "@reduxjs/toolkit";
import {
  addCategoryInvestment,
  addProductInvestment,
  getAllCategoryInvestment,
  getCategoryByDetail,
  getInvestmentByInvestor,
  getProductByDetail,
  getProductInvestmentByCurrency,
  updateCategoryInvestment,
  updateProductInvestment,
} from "../actions/investment/investment";
import { getAllProductInvestment } from "../actions/investment/getInvestments";
import { createInvestment } from "../actions/investment/createInvestment";
import { getCategories } from "../actions/investment/getCategories";
import { getInvestment } from "../actions/investment/getInvestment";
import { startInvestment } from "../actions/investment/startInvestment";
import { toggleInvestment } from "../actions/investment/toggleInvestment";
import { addPreviousRecords } from "../actions/investment/addPreviousRecords";
import { getPayoutHistory } from "../actions/investment/getPayoutHistory";
import { toggleInvestmentStatus } from "../actions/investment/toggleInvestmentStatus";
import { getInvestmentInterests } from "../actions/investment/getInvestmentInterests";
import { updateInvestment } from "../actions/investment/updateInvestment";
import { addBulkPreviousRecords } from "../actions/investment/addBulkPreviousRecords";
import { moveInvestmentUp } from "../actions/investment/moveInvestmentUp";
import { moveInvestmentdown } from "../actions/investment/moveInvestmentdown";
import { reOrderInvestment } from "../actions/investment/reOrderInvestment";
import { getInvestmentMetrics } from "../actions/transactions/getInvestmentMetrics";
import { getBusinesses } from "../actions/businesses/getBusinesses";
import { getBusinessProducts } from "../actions/businesses/getBusinessProducts";

export const investmentSlice = createSlice({
  name: "investments",
  initialState: {
    error: null,
    allInvestments: [],
    businesses: [],
    businessProducts: [],
    investmentByCategory: {},
    investmentByProduct: {},
    investmentProductCurrency: {},
    investmentCategoryPending: false,
    pending: false,
    togglePending: false,
    toggleStatusPending: false,
    investmentProductCurrencyPending: false,
    investmentByInvestorPending: false,
    investmentChangeOrderLoading: false,
    categories: [],
    investment: {},
    payoutHistory: [],
    investmentInterest: [],
    success: false,
    investmentMetrics: null,
  },

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(addCategoryInvestment.pending, (state, action) => {
      state.investmentCategoryPending = true;
    });
    builder.addCase(addCategoryInvestment.fulfilled, (state, action) => {
      state.investmentCategoryPending = false;
    });
    builder.addCase(addCategoryInvestment.rejected, (state, action) => {
      state.investmentCategoryPending = false;
      state.error = action.error.message;
    });
    builder.addCase(updateCategoryInvestment.pending, (state, action) => {
      state.investmentCategoryPending = true;
    });
    builder.addCase(updateCategoryInvestment.fulfilled, (state, action) => {
      state.investmentCategoryPending = false;
    });
    builder.addCase(updateCategoryInvestment.rejected, (state, action) => {
      state.investmentCategoryPending = false;
      state.error = action.error.message;
    });
    builder.addCase(getAllCategoryInvestment.pending, (state, action) => {
      state.investmentCategoryPending = true;
    });
    builder.addCase(getAllCategoryInvestment.fulfilled, (state, action) => {
      state.investmentCategoryPending = false;
      state.allInvestments = action.payload;
    });
    builder.addCase(getAllCategoryInvestment.rejected, (state, action) => {
      state.investmentCategoryPending = false;
      state.error = action.error.message;
    });
    builder.addCase(getInvestment.pending, (state, action) => {
      state.pending = true;
    });
    builder.addCase(getInvestment.fulfilled, (state, action) => {
      state.pending = false;
      state.investment = action.payload;
    });
    builder.addCase(getInvestment.rejected, (state, action) => {
      state.pending = false;
      state.error = action.error.message;
    });
    builder.addCase(addProductInvestment.pending, (state, action) => {
      state.investmentProductPending = true;
    });
    builder.addCase(addProductInvestment.fulfilled, (state, action) => {
      state.pending = false;
    });
    builder.addCase(addProductInvestment.rejected, (state, action) => {
      state.pending = false;
      state.error = action.error.message;
    });
    builder.addCase(updateInvestment.pending, (state, action) => {
      state.pending = true;
    });
    builder.addCase(updateInvestment.fulfilled, (state, action) => {
      state.pending = false;
    });
    builder.addCase(updateInvestment.rejected, (state, action) => {
      state.pending = false;
      state.error = action.error.message;
    });
    builder.addCase(getBusinessProducts.pending, (state, action) => {
      state.pending = true;
    });
    builder.addCase(getBusinessProducts.fulfilled, (state, action) => {
      state.pending = false;
      state.businessProducts = action.payload;
    });
    builder.addCase(getBusinessProducts.rejected, (state, action) => {
      state.pending = false;
      state.error = action.error.message;
    });
    builder.addCase(getBusinesses.pending, (state, action) => {
      state.pending = true;
    });
    builder.addCase(getBusinesses.fulfilled, (state, action) => {
      state.pending = false;
      state.businesses = action.payload;
    });
    builder.addCase(getBusinesses.rejected, (state, action) => {
      state.pending = false;
      state.error = action.error.message;
    });
    builder.addCase(getAllProductInvestment.pending, (state, action) => {
      state.pending = true;
    });
    builder.addCase(getAllProductInvestment.fulfilled, (state, action) => {
      state.pending = false;
      state.allInvestments = action.payload?.data;
      state.allInvestmentsInfo = action.payload;
    });
    builder.addCase(getAllProductInvestment.rejected, (state, action) => {
      state.pending = false;
      state.error = action.error.message;
    });
    builder.addCase(getProductByDetail.pending, (state, action) => {
      state.pending = true;
    });
    builder.addCase(getProductByDetail.fulfilled, (state, action) => {
      state.pending = false;
      state.investmentByProduct = action.payload;
    });
    builder.addCase(getProductByDetail.rejected, (state, action) => {
      state.pending = false;
      state.error = action.error.message;
    });
    builder.addCase(getProductInvestmentByCurrency.pending, (state, action) => {
      state.investmentProductCurrencyPending = true;
    });
    builder.addCase(
      getProductInvestmentByCurrency.fulfilled,
      (state, action) => {
        state.investmentProductCurrencyPending = false;
        state.investmentProductCurrency = action.payload;
      }
    );
    builder.addCase(
      getProductInvestmentByCurrency.rejected,
      (state, action) => {
        state.investmentProductCurrencyPending = false;
        state.error = action.error.message;
      }
    );
    builder.addCase(getInvestmentByInvestor.pending, (state, action) => {
      state.investmentByInvestorPending = true;
    });
    builder.addCase(getInvestmentByInvestor.fulfilled, (state, action) => {
      state.investmentProductCurrencyPending = false;
      state.investmentByInvestorPending = action.payload;
    });
    builder.addCase(getInvestmentByInvestor.rejected, (state, action) => {
      state.investmentByInvestorPending = false;
      state.error = action.error.message;
    });
    builder.addCase(createInvestment.pending, (state, action) => {
      state.pending = true;
    });
    builder.addCase(createInvestment.fulfilled, (state, action) => {
      state.pending = false;
    });
    builder.addCase(createInvestment.rejected, (state, action) => {
      state.pending = false;
      state.error = action.error.message;
    });
    builder.addCase(startInvestment.pending, (state, action) => {
      state.pending = true;
    });
    builder.addCase(startInvestment.fulfilled, (state, action) => {
      state.pending = false;
    });
    builder.addCase(startInvestment.rejected, (state, action) => {
      state.pending = false;
      state.error = action.error.message;
    });

    builder.addCase(addBulkPreviousRecords.pending, (state, action) => {
      state.pending = true;
    });
    builder.addCase(addBulkPreviousRecords.fulfilled, (state, action) => {
      state.pending = false;
    });
    builder.addCase(addBulkPreviousRecords.rejected, (state, action) => {
      state.pending = false;
      state.error = action.error.message;
    });

    builder.addCase(getCategories.fulfilled, (state, action) => {
      state.pending = false;
      state.categories = action.payload;
    });
    builder.addCase(getCategories.rejected, (state, action) => {
      state.pending = false;
      state.error = action.error.message;
    });
    builder.addCase(toggleInvestment.pending, (state, action) => {
      state.togglePending = true;
    });
    builder.addCase(toggleInvestment.fulfilled, (state, action) => {
      state.togglePending = false;
    });
    builder.addCase(toggleInvestment.rejected, (state, action) => {
      state.togglePending = false;
      state.error = action.error.message;
    });

    builder.addCase(toggleInvestmentStatus.pending, (state, action) => {
      state.toggleStatusPending = true;
    });
    builder.addCase(toggleInvestmentStatus.fulfilled, (state, action) => {
      state.toggleStatusPending = false;
    });
    builder.addCase(toggleInvestmentStatus.rejected, (state, action) => {
      state.toggleStatusPending = false;
      state.error = action.error.message;
    });

    builder.addCase(addPreviousRecords.pending, (state, action) => {
      state.pending = true;
    });
    builder.addCase(addPreviousRecords.fulfilled, (state, action) => {
      state.pending = false;
    });
    builder.addCase(addPreviousRecords.rejected, (state, action) => {
      state.pending = false;
      state.error = action.error.message;
    });

    builder.addCase(getPayoutHistory.pending, (state, action) => {
      state.pending = true;
    });
    builder.addCase(getPayoutHistory.fulfilled, (state, action) => {
      state.pending = false;
      state.payoutHistory = action.payload;
    });
    builder.addCase(getPayoutHistory.rejected, (state, action) => {
      state.pending = false;
      state.error = action.error.message;
    });
    builder.addCase(getInvestmentInterests.pending, (state, action) => {
      state.pending = true;
    });
    builder.addCase(getInvestmentInterests.fulfilled, (state, action) => {
      state.pending = false;
      state.investmentInterest = action.payload;
    });
    builder.addCase(getInvestmentInterests.rejected, (state, action) => {
      state.pending = false;
      state.error = action.error.message;
    });
    builder.addCase(moveInvestmentUp.pending, (state, action) => {
      state.investmentChangeOrderLoading = true;
    });
    builder.addCase(moveInvestmentUp.fulfilled, (state, action) => {
      state.investmentChangeOrderLoading = false;
      state.investment = action.payload;
    });
    builder.addCase(moveInvestmentUp.rejected, (state, action) => {
      state.investmentChangeOrderLoading = false;
      state.error = action.error.message;
    });
    builder.addCase(moveInvestmentdown.pending, (state, action) => {
      state.investmentChangeOrderLoading = true;
    });
    builder.addCase(moveInvestmentdown.fulfilled, (state, action) => {
      state.investmentChangeOrderLoading = false;
      state.investment = action.payload;
    });
    builder.addCase(moveInvestmentdown.rejected, (state, action) => {
      state.investmentChangeOrderLoading = false;
      state.error = action.error.message;
    });
    builder.addCase(reOrderInvestment.pending, (state, action) => {
      state.investmentChangeOrderLoading = true;
    });
    builder.addCase(reOrderInvestment.fulfilled, (state, action) => {
      state.investmentChangeOrderLoading = false;
      state.investment = action.payload;
    });
    builder.addCase(reOrderInvestment.rejected, (state, action) => {
      state.investmentChangeOrderLoading = false;
      state.error = action.error.message;
    });
    builder.addCase(getInvestmentMetrics.pending, (state, action) => {
      state.investmentMetrics = null;
    });
    builder.addCase(getInvestmentMetrics.fulfilled, (state, action) => {
      state.investmentMetrics = action.payload;
    });
  },
});

export default investmentSlice.reducer;
