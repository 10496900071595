import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../components/Buttons/Button";
import CustomInput from "../../components/inputs/CustomInput";
import { login } from "../../store/actions/onboarding/login";
import { useFormik } from "formik";
import { loginValidationSchema } from "../../Helper/validationSchemas";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.onboarding.pending);

  const handleLogin = () => {
    dispatch(login(form.values)).then(() => {
      navigate("/");
    });
  };

  const form = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: handleLogin,
    validationSchema: loginValidationSchema,
  });

  return (
    <div className="min-h-screen flex flex-col items-center overflow-hidden">
      <div className="  w-full h-screen flex items-center justify-center ">
        <div className="border-[#D0D5DD] px-20 pt-10 rounded-lg mb-10 h-min border-[0.8px] ">
          <h2 className=" w-full text-4xl text-text-color-1 font-Source-Sans-Pro leading-[44px] my-4 font-[600]   leading-36px sm:mb-2  tracking-[1%] justify-center  ">
            Welcome Back
          </h2>
          {/* <div className=" ">
            <p className="text-sm text-[#475467] font-[400] ">
              Welcome back! Please enter your details.
            </p>
          </div> */}
          <div className="max-w-[360px] h-[498px]  flex flex-col items-center ">
            <div className="w-full mt-6">
              <label className="font-[400] text-sm font-Source-Sans-Pro">
                Email
              </label>
              <CustomInput
                width="360px"
                // height="15px"
                id="email"
                form={form}
                placeholder={"Enter your email"}
                borderColor="#D0D5DD"
                borderRadius="8px"
              />
            </div>
            <div className="w-full mt-2">
              <label className="font-[400] text-sm font-Source-Sans-Pro">
                Password
              </label>
              <CustomInput
                width="360px"
                id="password"
                //  height="16px"
                type="password"
                form={form}
                placeholder={"Enter your password"}
                borderColor="#D0D5DD"
                borderRadius="8px"
              />
            </div>

            <div className="w-full">
              <CustomButton
                variant="contained"
                customColor="#159AA8"
                loading={loading}
                width="360px"
                height="50px"
                padding="10px"
                margin="36px 0px"
                color="#fff"
                onClick={form.handleSubmit}
              >
                Sign In
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
