import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../Helper/proccessErrorMessage";
import { API_CONVERT_TO_CYCLE } from "../../../config/api";
import { replace } from "formik";

export const convertToCycle = createAsyncThunk(
  "investment/convertToCycle",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "PUT",
        path: `${API_CONVERT_TO_CYCLE}${payload?.id}`,
        data: payload.data,
        serializerConfig: { indexes: null },
      });

      toast.success(response?.data?.message);
      payload?.cb();
      return response?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      payload?.failed();
      return rejectWithValue(error.message);
    }
  }
);
