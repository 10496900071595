import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";

import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch, useSelector } from "react-redux";
import { ChevronRight } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { getAllProductInvestment } from "../../store/actions/investment/getInvestments";
import { convertToCycle } from "../../store/actions/businesses/convertToCycle";
import Loader from "../SharedLayouts/Loader";
import { toast } from "react-toastify";
import { addInvestmentToBusiness } from "../../store/actions/investment/addInvestmentToBusiness";

function AddCampaignTypes({
  open,
  onClose,
  name,
  refresh,
  businessProduct,
  setShowAdminModal,
  onProceed,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [convert, setConvert] = useState(false);
  const [getingRoles, setGetingRoles] = useState(true);

  const adminLoading = useSelector((state) => state?.user?.loading) || false;

  const [loading, setLoading] = useState(false);
  const { roles } = useSelector((state) => state?.dashboard);

  const allInvestments =
    useSelector((state) => state?.investment?.allInvestments) || [];

  const allInvestmentsInfo =
    useSelector((state) => state?.investment?.allInvestmentsInfo) || [];
  const pending = useSelector((state) => state?.investment?.pending) || false;

  return convert ? (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="custom-modal-title"
      aria-describedby="custom-modal-description"
      className="justify-center items-center flex"
    >
      <div className="w-[600px] mx-auto h-[60vh] overflow-y-scroll z-[100]  border bg-[#FFF]">
        <div className="flex justify-between mt-2 items-center">
          <div></div>
          <IconButton
            aria-label="close"
            sx={
              {
                // position: "absolute",
                // top: 19,
                // right: 10,
              }
            }
            onClick={onClose}
          >
            <ClearIcon />
          </IconButton>
        </div>
        <div className="pt-3 pb-5 px-5">
          <h4 className="mb-4 text-[20px] font-[500]">Add Existing Campaign</h4>
          {loading ? (
            <Loader />
          ) : (
            allInvestments?.map((item, index) => (
              <div className="cursor-pointer border-b-[#e5e7eb] border-b-[1px] mb-3 flex justify-between items-center">
                <h4 className="mb-0 text-[14px] font-[500]">
                  {index + 1} {item.name}
                </h4>
                <h5
                  onClick={() => {
                    setLoading(true);

                    const payload = {
                      data: {
                        business_id: id,
                      },
                      investment_product_id: item?.id,

                      cb: () => {
                        setLoading(false);
                        refresh();
                        toast.success(
                          "Investment Added to Business Successfully."
                        );
                      },
                      failed: () => {
                        setLoading(false);
                      },
                    };

                    dispatch(addInvestmentToBusiness(payload));
                  }}
                  className="cursor-pointer text-[#6935D3]"
                >
                  Add
                </h5>
              </div>
            ))
          )}
        </div>
      </div>
    </Modal>
  ) : (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="custom-modal-title"
      aria-describedby="custom-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 540,
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: 24,
          p: 2,
        }}
      >
        <IconButton
          aria-label="close"
          sx={{
            position: "absolute",
            top: 19,
            right: 10,
          }}
          onClick={onClose}
        >
          <ClearIcon />
        </IconButton>
        <div className="w-full p-5 mt-4">
          <div>
            <div className=" mb-4 lg:mb:0  w-full">
              <div
                onClick={onProceed}
                className="cursor-pointer flex justify-between items-center"
              >
                <h4 className="mb-6 text-[16px] font-[500]">Add Campaign</h4>
                <ChevronRight />
              </div>
              <div
                onClick={() => {
                  setConvert(true);
                }}
                className="cursor-pointer flex justify-between items-center"
              >
                <h4 className="mb-0 text-[16px] font-[500]">
                  Add Existing Campaign
                </h4>
                <ChevronRight />
              </div>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default AddCampaignTypes;
