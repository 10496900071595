import React, { useState } from "react";
import Loader from "../../SharedLayouts/Loader";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import GridTable from "../../table/gridTable";
import { useDispatch, useSelector } from "react-redux";
import useCurrency from "../../../hooks/useCurrency";
import InvestmentMenu from "../../menus/InvestmentMenu";
import Dots from "../../Icons/Dots";
import { getCurrencySymbol } from "../../../Helper/getCurrencySymbol";
import { investmentFilterOptions } from "../../../config/data";
import { setInvestmentFilter } from "../../../store/reducers/dashboardSlice";
import AddInvestmentToBusiness from "../../modal/AddInvestmentToBusiness";

const InvestmentTable = () => {
  const dispatch = useDispatch();

  const [selectedUserId, setSelectedUserId] = useState(null);
  const [_currency, getAmount] = useCurrency();

  const [showPopper, setShowPopper] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { investmentFilter } = useSelector((state) => state?.dashboard);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRows, setSelectedRows] = useState(null);
  const [contextMenu, setContextMenu] = useState(null);
  const [selectionModel, setSelectionModel] = React.useState([]);

  const allInvestments =
    useSelector((state) => state?.investment?.allInvestments) || [];

  const allInvestmentsInfo =
    useSelector((state) => state?.investment?.allInvestmentsInfo) || [];
  const loading = useSelector((state) => state?.investment?.pending) || false;
  const navigate = useNavigate();

  const handleContextMenu = (event) => {
    event.preventDefault();
    setSelectedUserId(Number(event.currentTarget.getAttribute("data-id")));
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleRowClick = (params, event) => {
    const isIconClick = event.target.getAttribute("data-is-icon") === "true";
    setSelectedUserId(params?.row?.investment_id || params?.row?.id);

    if (!isIconClick) {
      navigate(`/investment/${params?.row?.id}`);
    }

    const selectedInvestment = allInvestments.find(
      (req) =>
        req.id === params?.row?.investment_id || req.id === params?.row?.id
    );
    setSelectedRow(selectedInvestment);
  };

  console.log(allInvestments, "allInvestments");

  const handleClosePopper = () => {
    setShowPopper(false);
  };

  const onRowsSelectionHandler = (ids) => {
    setSelectedRows(ids);
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
    },
    { field: "name", headerName: "Name", width: 140 },

    {
      field: "total_amount_invested",
      headerName: "Amount Invested",
      width: 200,
      renderCell: (params) =>
        `${getCurrencySymbol(params.row?.currency)} ${getAmount(
          Number(params.row?.total_amount_invested)
        )}`,
    },
    {
      field: "target",
      headerName: "Target",
      width: 150,
      renderCell: (params) =>
        `${getCurrencySymbol(params.row?.currency)} ${getAmount(
          Number(params.row?.maximum_amount)
        )}`,
    },
    {
      field: "category",
      headerName: "Category",
      width: 250,
      renderCell: (params) =>
        params.row?.category?.name || params.row?.investment_category?.name,
    },
    {
      field: "noOfInvestment",
      headerName: "No of Investors",
      width: 150,
      renderCell: (params) =>
        params.row?.investors?.length || params.row?.investments?.length,
    },
    {
      field: "campaign_start_date",
      headerName: "Campaign Start Date",
      width: 150,
    },
    {
      field: "investment_status",
      headerName: "Status",
      width: 97,
      renderCell: (params) => (
        <div
          style={{
            display: "inline-block",
            width: params.row?.status === "1" ? "63px" : "70px",
            height: "22px",
            borderRadius: "10px",
            textAlign: "center",
            lineHeight: "22px",
            color: params.row?.status === "1" ? "#067647" : "#B42318",
            backgroundColor: params.row?.status === "1" ? "#ECFDF3" : "#FECDCA",
          }}
        >
          {params.row?.status == "1" ? "Active" : "Closed"}
        </div>
      ),
    },
    {
      renderCell: (params) => (
        <>
          <div
            style={{
              cursor: "pointer",
              position: "relative",
            }}
            onClick={(e) => {
              e.target.setAttribute("data-is-icon", "true");
              handleContextMenu(e);
            }}
          >
            <Dots />
          </div>
        </>
      ),
    },
  ];

  const handleFilterClick = (id) => {
    dispatch(setInvestmentFilter(id));
  };

  const [showAddToBusiness, setShowAddToBusiness] = useState(false);

  const handleAddToABusiness = () => {
    setShowAddToBusiness(true);
  };

  return (
    <div className="mt-10 mb-8">
      <div className="mb-8 cursor-pointer w-full overflow-x-auto">
        <div className="w-[max-content]  mt-8 flex items-center ">
          {investmentFilterOptions?.map((item) => {
            return (
              <span
                key={item.id}
                className={`${
                  investmentFilter === item.id
                    ? "border-b-2 border-[#128591] text-[#128591] font-[600]"
                    : "text-gray-700"
                } mr-3 text-base`}
                onClick={() => handleFilterClick(item.id)}
              >
                {item.name}
              </span>
            );
          })}
        </div>
      </div>

      {loading && allInvestments?.length < 1 ? (
        <Loader />
      ) : (
        <Box sx={{ overflowX: "auto", width: "100%", cursor: "pointer" }}>
          <GridTable
            rows={allInvestments?.filter(
              (i) =>
                i?.category?.name !== "SME Financing" &&
                i?.investment_category?.name !== "SME Financing"
            )}
            // rows={allInvestments?.reduce((acc, curr) => {
            //   const { cycles, ...rest } = curr;
            //   return [
            //     ...acc,
            //     ...cycles.map((i) => ({
            //       ...rest,
            //       ...i,
            //     })),
            //   ];
            // }, [])}
            // pageInfo={allInvestmentsInfo}
            searchPlaceholder={"Search for Investments"}
            columns={columns}
            type={"investment"}
            componentsProps={{
              row: {
                onContextMenu: handleContextMenu,
                style: { cursor: "context-menu" },
              },
            }}
            handleRowClick={(params, event) => handleRowClick(params, event)}
            rowSelectionModel={selectionModel}
            onRowSelectionModelChange={(ids) => {
              setSelectionModel(ids);
              onRowsSelectionHandler(ids);
            }}
          />
        </Box>
      )}

      <AddInvestmentToBusiness
        selectedRow={selectedRow}
        onClose={() => {
          setShowAddToBusiness(false);
        }}
        open={showAddToBusiness}
      />

      <InvestmentMenu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
        handleAddToABusiness={handleAddToABusiness}
        handleClose={handleClose}
        selectedRow={selectedRow}
        componentsProps={{
          root: {
            onContextMenu: (e) => {
              e.preventDefault();
              handleClose();
            },
          },
        }}
        anchorEl={anchorEl}
      />
    </div>
  );
};

export default InvestmentTable;
