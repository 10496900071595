import React, { useCallback, useEffect, useRef, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Client } from "../../client";
import { toast } from "react-toastify";
import SearchIcon from "../Icons/SearchIcon";
import ExportExcel from "../Export/ExportExcel";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";

const GridTable = ({
  type,
  rows = [],
  pageInfo,
  noReport,
  columns,
  searchPlaceholder,
  handleRowClick,
  activeFilter,
  noSearch,
  disableRowSelectionOnClick = true,
  ...props
}) => {
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 50,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [fetchedData, setFetchedData] = useState(null);
  const [_rows, setRows] = useState([]);
  const [searchRows, setSearchRows] = useState(null);

  const serverSearching = async ({ url }) => {
    try {
      setIsLoading(true);
      const response = await Client({
        method: "GET",
        path: url,
        type: "full-path",
      });

      setIsLoading(false);
      if (response?.data && response?.data?.length > 0) {
        return response?.data;
      } else {
        return null;
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Error Fetching Data");
    }
  };
  const loadNewData = async ({ url }) => {
    try {
      setIsLoading(true);
      const response = await Client({
        method: "GET",
        path: url,
        type: "full-path",
      });

      if (response?.data?.data && response?.data?.data?.length > 0) {
        setFetchedData((prev) => [...prev, ...response?.data?.data]);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Error Fetching Data");
    }
  };

  useEffect(() => {
    if (pageInfo?.total) {
      setFetchedData(pageInfo?.data);

      // setRows(pageInfo?.data);
    }
  }, [pageInfo]);

  const { userFilter, investmentFilter } = useSelector(
    (state) => state?.dashboard
  );

  useEffect(() => {
    let filterRow = [];
    if (pageInfo?.total && fetchedData) {
      const newRows = [...fetchedData]?.slice(
        paginationModel?.page
          ? paginationModel?.page * paginationModel?.pageSize
          : 0,
        paginationModel?.page
          ? paginationModel?.page * paginationModel?.pageSize +
              paginationModel?.pageSize
          : paginationModel?.pageSize
      );

      if (
        // newRows?.length < paginationModel?.pageSize &&
        newRows?.length === 0 &&
        fetchedData.length < pageInfo?.total
      ) {
        loadNewData({
          url: `${pageInfo?.path}?page=${paginationModel?.page + 1}`,
        });

        return;
      } else {
        // setRows([...newRows]);
        filterRow = newRows;
      }

      // next_page_url

      // loadNewData()
    } else {
      filterRow = [...rows]?.slice(
        paginationModel?.page
          ? paginationModel?.page * paginationModel?.pageSize
          : 0,
        paginationModel?.page
          ? paginationModel?.page * paginationModel?.pageSize +
              paginationModel?.pageSize
          : paginationModel?.pageSize
      );
      // setRows(
      //   [...rows]?.slice(
      //     paginationModel?.page
      //       ? paginationModel?.page * paginationModel?.pageSize
      //       : 0,
      //     paginationModel?.page
      //       ? paginationModel?.page * paginationModel?.pageSize +
      //           paginationModel?.pageSize
      //       : paginationModel?.pageSize
      //   )
      // );
    }

    if (type === "users-main") {
      if (userFilter === 1) {
        setRows((prev) => [...filterRow]);
      } else if (userFilter === 2) {
        setRows((prev) => [
          ...filterRow?.filter((i) => i?.status === "Profile Incomplete"),
        ]);
      } else if (userFilter === 3) {
        setRows((prev) => [
          ...filterRow?.filter((i) => i?.status === "Non-Investing"),
        ]);
      } else if (userFilter === 4) {
        setRows((prev) => [
          ...filterRow?.filter((i) => i?.status === "Unverified"),
        ]);
      } else if (userFilter === 5) {
        setRows((prev) => [
          ...filterRow?.filter((i) => i?.status === "Investor"),
        ]);
      } else if (userFilter === 6) {
        setRows((prev) => [
          ...filterRow?.filter((i) => i?.status === "Inactive"),
        ]);
      }
    } else if (type === "investment") {
      if (investmentFilter === 1) {
        setRows([...filterRow]);
      } else if (investmentFilter === 2) {
        setRows([
          ...filterRow?.filter((i) => i?.status === "1" || i?.status === 1),
        ]);
      } else if (investmentFilter === 3) {
        setRows([...filterRow?.filter((i) => i?.status == "invested")]);
      } else {
        setRows([...filterRow]);
      }
    } else {
      setRows([...filterRow]);
    }
  }, [
    rows,
    paginationModel,
    pageInfo,
    fetchedData,
    userFilter,
    investmentFilter,
  ]);

  const rowCountRef = React.useRef(pageInfo?.totalRowCount || 0);

  const rowCount = React.useMemo(() => {
    if (pageInfo?.total !== undefined) {
      rowCountRef.current = pageInfo.total;
    }
    return rowCountRef.current || rows?.length || 0;
  }, [pageInfo?.total, rows?.length]);

  const [searchWord, setSearchWord] = useState("");

  let timeout = useRef();

  const searching = useCallback(
    async (value) => {
      clearTimeout(timeout.current);
      setSearchWord(value);

      let searchResponse = null;
      if (type === "users") {
        if (
          value?.length > 1 &&
          !_rows?.some((row) => {
            return (
              row?.email?.toLowerCase()?.includes(value?.toLowerCase()) ||
              row?.name?.toLowerCase()?.includes(value?.toLowerCase())
            );
          })
        ) {
          timeout.current = setTimeout(async () => {
            searchResponse = await serverSearching({
              url: `${
                process.env.REACT_APP_APP_ENV === "development"
                  ? process.env.REACT_APP_DEV_BASE_URL
                  : process.env.REACT_APP_APP_ENV === "staging"
                  ? process.env.REACT_APP_STAGING_BASE_URL
                  : process.env.REACT_APP_PRODUCTION_BASE_URL
              }api/v1/Management/user/search?searchTerm=${value}`,
            });

            if (userFilter === 1) {
              setSearchRows(searchResponse);
            } else if (userFilter === 2) {
              setSearchRows((prev) => [
                ...searchResponse?.filter(
                  (i) => i?.selectedPlan === "Platinum"
                ),
              ]);
            } else if (userFilter === 3) {
              setSearchRows((prev) => [
                ...searchResponse?.filter((i) => i?.selectedPlan === "free"),
              ]);
            } else if (userFilter === 4) {
              setSearchRows((prev) => [
                ...searchResponse?.filter(
                  (i) =>
                    i?.selectedPlan === null ||
                    i?.investorProfile === null ||
                    i?.hasProfileCompleted === false
                ),
              ]);
            }
          }, 1500);
        } else {
          clearTimeout(timeout.current);

          setSearchRows(null);
        }
      } else if (type === "investment") {
        if (
          value?.length > 1 &&
          !_rows?.some((row) => {
            return row?.name?.toLowerCase()?.includes(value?.toLowerCase());
          })
        ) {
          timeout.current = setTimeout(async () => {
            searchResponse = await serverSearching({
              url: `${
                process.env.REACT_APP_APP_ENV === "development"
                  ? process.env.REACT_APP_DEV_BASE_URL
                  : process.env.REACT_APP_APP_ENV === "staging"
                  ? process.env.REACT_APP_STAGING_BASE_URL
                  : process.env.REACT_APP_PRODUCTION_BASE_URL
              }api/v1/investment/search?name=${value}`,
            });

            if (investmentFilter === 1) {
              setSearchRows(searchResponse);
            } else if (investmentFilter === 2) {
              setSearchRows([
                ...searchResponse?.filter(
                  (i) => i?.status === "1" || i?.status === 1
                ),
              ]);
            } else if (investmentFilter === 3) {
              setSearchRows([
                ...searchResponse?.filter(
                  (i) => i?.status !== "1" && i?.status !== 1
                ),
              ]);
            } else {
              setSearchRows([...searchResponse]);
            }
          }, 2500);
        } else {
          setSearchRows(null);
        }
      } else if (type === "transaction") {
        if (
          value?.length > 10 &&
          !_rows?.some((row) => {
            return row?.transaction_id
              ?.toString()
              ?.includes(value?.toLowerCase());
          })
        ) {
          timeout.current = setTimeout(async () => {
            searchResponse = await serverSearching({
              url: `https://api-staging.halvestco.com/api/v1/transactions/searchHistories?id=${value}`,
            });
            setSearchRows(searchResponse);
          }, 2500);
        } else {
          setSearchRows(null);
        }
      } else if (type === "payoutHistory") {
        if (
          value?.length > 1 &&
          !_rows?.some((row) => {
            return row?.id?.toString()?.includes(value?.toLowerCase());
          })
        ) {
          timeout.current = setTimeout(async () => {
            searchResponse = await serverSearching({
              url: `https://api-staging.halvestco.com/api/v1/investment/searchPayoutHistories?id${value}`,
            });

            setSearchRows(searchResponse);
          }, 2500);
        } else {
          setSearchRows(null);
        }
      } else {
        setSearchRows(
          _rows?.filter((row) => {
            return (
              row?.Name?.toLowerCase()?.includes(value?.toLowerCase()) ||
              row?.name?.toLowerCase()?.includes(value?.toLowerCase()) ||
              row?.firstName?.toLowerCase()?.includes(value?.toLowerCase()) ||
              row?.lastName?.toLowerCase().includes(value?.toLowerCase()) ||
              row?.email?.toLowerCase().includes(value?.toLowerCase()) ||
              row?.Email?.toLowerCase().includes(value?.toLowerCase())
            );
          })
        );
        // }
      }
    },
    [userFilter, investmentFilter, _rows, type]
  );

  useEffect(() => {
    return clearTimeout(timeout.current);
  }, [timeout]);
  return (
    <div className="bg-white p-2 md:p-6 border-[#D0D5DD] border-[0.8px] rounded-lg shadow-md">
      <div className=" flex justify-between mb-2 items-center">
        <div>
          {noSearch ? (
            <></>
          ) : (
            <div
              className="search-wrapper hidden lg:flex items-center "
              style={{ width: "400px", height: "44px" }}
            >
              <SearchIcon />
              <input
                onChange={(e) => searching(e.target.value)}
                placeholder={searchPlaceholder || "Search"}
              />
            </div>
          )}
        </div>
        {noReport ? (
          <></>
        ) : (
          <ExportExcel
            type={type}
            rows={
              searchRows && searchWord?.length > 1
                ? searchRows
                : searchWord?.length <= 1
                ? fetchedData || _rows
                : fetchedData?.filter((row) => {
                    return (
                      row?.Name?.toLowerCase().includes(
                        searchWord?.toLowerCase()
                      ) ||
                      row?.name
                        ?.toLowerCase()
                        .includes(searchWord?.toLowerCase()) ||
                      row?.firstName
                        ?.toLowerCase()
                        .includes(searchWord?.toLowerCase()) ||
                      row?.lastName
                        ?.toLowerCase()
                        .includes(searchWord?.toLowerCase()) ||
                      row?.email
                        ?.toLowerCase()
                        .includes(searchWord?.toLowerCase()) ||
                      row?.Email?.toLowerCase().includes(
                        searchWord?.toLowerCase()
                      ) ||
                      row?.investor_name
                        ?.toLowerCase()
                        .includes(searchWord?.toLowerCase()) ||
                      row?.transaction_id
                        ?.toString()
                        .includes(searchWord?.toLowerCase())
                    );
                  }) || []
            }
            columns={columns}
          />
        )}
      </div>

      <Box sx={{ overflowX: "auto", width: "100%", cursor: "pointer" }}>
        <DataGrid
          {...props}
          sx={{
            border: "none",
          }}
          hideFooterPagination={isLoading || searchRows}
          rowCount={rowCount}
          loading={isLoading}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          rows={
            isLoading
              ? _rows
              : searchRows && searchWord?.length > 1
              ? searchRows
              : searchWord?.length <= 1
              ? _rows
              : fetchedData
              ? fetchedData?.filter((row) => {
                  return (
                    row?.Name?.toLowerCase()?.includes(
                      searchWord?.toLowerCase()
                    ) ||
                    row?.name
                      ?.toLowerCase()
                      ?.includes(searchWord?.toLowerCase()) ||
                    row?.firstName
                      ?.toLowerCase()
                      ?.includes(searchWord?.toLowerCase()) ||
                    row?.lastName
                      ?.toLowerCase()
                      .includes(searchWord?.toLowerCase()) ||
                    row?.email
                      ?.toLowerCase()
                      .includes(searchWord?.toLowerCase()) ||
                    row?.Email?.toLowerCase().includes(
                      searchWord?.toLowerCase()
                    ) ||
                    row?.investor_name
                      ?.toLowerCase()
                      .includes(searchWord?.toLowerCase()) ||
                    row?.transaction_id
                      ?.toString()
                      .includes(searchWord?.toLowerCase())
                  );
                })
              : type === "transaction"
              ? rows?.filter((row) => {
                  return (
                    row?.Name?.toLowerCase()?.includes(
                      searchWord?.toLowerCase()
                    ) ||
                    row?.name
                      ?.toLowerCase()
                      ?.includes(searchWord?.toLowerCase()) ||
                    row?.firstName
                      ?.toLowerCase()
                      ?.includes(searchWord?.toLowerCase()) ||
                    row?.lastName
                      ?.toLowerCase()
                      .includes(searchWord?.toLowerCase()) ||
                    row?.email
                      ?.toLowerCase()
                      .includes(searchWord?.toLowerCase()) ||
                    row?.Email?.toLowerCase().includes(
                      searchWord?.toLowerCase()
                    ) ||
                    row?.investor_name
                      ?.toLowerCase()
                      .includes(searchWord?.toLowerCase()) ||
                    row?.transaction_id
                      ?.toString()
                      .includes(searchWord?.toLowerCase())
                  );
                })
              : []
          }
          columns={columns}
          pageSize={10}
          pageSizeOptions={[50]}
          checkboxSelection
          disableRowSelectionOnClick={disableRowSelectionOnClick}
          onRowClick={handleRowClick}
          // components={{
          //   Toolbar: CustomToolbar,
          // }}
        />
      </Box>
    </div>
  );
};

export default GridTable;
